import React, { MouseEventHandler } from "react";
import { extendVariants, Button } from "@nextui-org/react";

export const CustomButton = extendVariants(Button, {
  variants: {
    color: {
      red: "text-[#ffffff] bg-[#a12121]",
    },
  },
});

interface NextButtonProps {
  children?: React.ReactNode;
  variant?:
    | "solid"
    | "bordered"
    | "light"
    | "flat"
    | "faded"
    | "shadow"
    | "ghost";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger"
    | "red";
  size?: "sm" | "md" | "lg";
  radius?: "none" | "sm" | "md" | "lg" | "full";
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  spinner?: React.ReactNode;
  spinnerPlacement?: "start" | "end";
  fullWidth?: boolean;
  isIconOnly?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  disableRipple?: boolean;
  disableAnimation?: boolean;
  onPress?: () => void;
  onPressStart?: () => void;
  onPressEnd?: () => void;
  onPressChange?: () => void;
  onPressUp?: () => void;
  onKeyDown?: () => void;
  onKeyUp?: () => void;
  onClick?: MouseEventHandler;
  className?: string;
}

/**
 *
 * @param param0 children, variant, color, size, radius, startContent, endContent, spinner, spinnerPlacement, fullWidth, isIconOnly, isDisabled, isLoading, disableRipple, disableAnimation, onPress, onPressStart, onPressEnd, onPressChange, onPressUp, onKeyDown, onKeyUp, onClick, className
 * @returns NextUI Button
 */
const NextButton: React.FC<NextButtonProps> = ({
  children,
  variant = "solid",
  color = "default",
  size = "md",
  radius = "none",
  startContent,
  endContent,
  spinner,
  spinnerPlacement = "start",
  fullWidth = false,
  isIconOnly = false,
  isDisabled = false,
  isLoading = false,
  disableRipple = false,
  disableAnimation = false,
  onPress,
  onPressStart,
  onPressEnd,
  onPressChange,
  onPressUp,
  onKeyDown,
  onKeyUp,
  onClick,
  className,
}) => {
  return (
    <CustomButton
      variant={variant}
      color={color}
      size={size}
      radius={radius}
      startContent={startContent}
      endContent={endContent}
      spinner={spinner}
      spinnerPlacement={spinnerPlacement}
      fullWidth={fullWidth}
      isIconOnly={isIconOnly}
      isDisabled={isDisabled}
      isLoading={isLoading}
      disableRipple={disableRipple}
      disableAnimation={disableAnimation}
      onPress={onPress}
      onPressStart={onPressStart}
      onPressEnd={onPressEnd}
      onPressChange={onPressChange}
      onPressUp={onPressUp}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onClick={onClick}
      className={className}
    >
      {children}
    </CustomButton>
  );
};

export default NextButton;
