import { useCallback, useState } from "react";
import saveIcon from "/app/src/components/generic/title/saveIcon.svg";
import { Formik } from "formik";
import { Widget } from "/app/src/models";
import EditButton from "/app/src/components/generic/components/buttons/EditButton";
import { simpleSchemaBuilder } from "/app/src/helpers";
import "/app/src/components/dashboard/widget/edit/editTitle.scss";

export default function Title({
  widget,
  updateWidget,
}: {
  widget: Widget;
  updateWidget: (widget: Widget) => void;
}) {
  const [editingTitle, setEditingTitle] = useState(false);
  const handleEditClick = (): void => {
    setEditingTitle(true);
  };
  const handleSubmit = useCallback(
    (values, actions) => {
      updateWidget({ id: widget.id, name: values.name });
      actions.resetForm();
      setEditingTitle(false);
    },
    [updateWidget, widget],
  );

  return (
    <div className="title">
      {editingTitle ? (
        <Formik
          initialValues={{
            name: widget.name,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className="flex justify-between items-center">
                <div className="inputContainer">
                  <input
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.name}
                    name="name"
                  />
                </div>
                <button className="" type="submit">
                  <img src={saveIcon} alt="Save" />
                </button>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div className="noEdit">
          <span className="title">{widget.name}</span>
          <EditButton onClick={handleEditClick} />
        </div>
      )}
    </div>
  );
}
