import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { binService } from "/app/src/services";
import { Bin as BinType } from "/app/src/models";
import Search from "../search";
import { useTranslation } from "react-i18next";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

export default function Bins() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<BinType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("width", {
        id: "width",
        cell: (info) => info.getValue(),
        header: () => t("translation:width"),
      }),
      columnHelper.accessor("height", {
        id: "height",
        cell: (info) => info.getValue(),
        header: () => t("translation:height"),
      }),
      columnHelper.accessor("depth", {
        id: "depth",
        cell: (info) => info.getValue(),
        header: () => t("translation:depth"),
      }),
    ],
    [columnHelper, t],
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const goToBin = (binId: number) => {
    navigate(`/explorer/bins/${binId}`);
  };

  //Get the bins
  const { data: bins, isFetching } = useQuery({
    queryKey: ["bins", page, pageSize, sort, searchString],
    queryFn: () => {
      return binService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { bins: [] },
    select: (data: { bins: BinType[] }) => {
      return data.bins;
    },
  });

  //Get the number of bins
  const { data: binsCount } = useQuery({
    queryKey: ["binsCount", searchString],
    queryFn: () => {
      return binService.getCount(buildParams({ search: searchString }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:bins")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <TableUpgrade
        loading={isFetching}
        rows={bins}
        tableColumns={columns}
        length={binsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        enableRowSelection
        rowClicked={goToBin}
        emptyText={t("translation:no_bins")}
      />
    </div>
  );
}
