import React, { useMemo } from "react";
import { workflowHistoryService } from "/app/src/services";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { useTranslation } from "react-i18next";
import getOrderByQuery from "/app/src/helpers/table";
import { useQuery } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers";
import { WorkflowHistory } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

export default function WorkflowHistories({
  workflowId,
  setWorkflowHistoryId,
}: {
  workflowId: number | undefined;
  setWorkflowHistoryId: (id: number) => void;
}) {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<WorkflowHistory>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => (
          <DateTime date={info.getValue()} format={"MMMM Do YYYY, h:mm:ss a"} />
        ),
        header: t("translation:workflow_run_time"),
      }),
    ],
    [columnHelper, t],
  );

  const [sort, setSort] = useSortUpgrade([]);

  const { data: workflowHistories, isFetching } = useQuery({
    queryKey: ["workflowHistories", workflowId, sort],
    queryFn: () => {
      return workflowHistoryService.getAll(
        buildParams({
          workflowId,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { workflow_histories: [] },
    select: (data: { workflow_histories: WorkflowHistory[] }) => {
      return data.workflow_histories;
    },
  });

  const { data: workflowHistoriesCount } = useQuery({
    queryKey: ["workflowHistoriesCount", workflowId],
    queryFn: () => {
      return workflowHistoryService.getCount(
        buildParams({
          workflowId,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="workflow">
      <TableUpgrade
        loading={isFetching}
        rows={workflowHistories}
        tableColumns={columns}
        length={workflowHistoriesCount}
        sort={sort}
        setSort={setSort}
        rowClicked={(id: number) => {
          setWorkflowHistoryId(id);
        }}
        enableRowSelection
        emptyText={t("translation:workflow_runs_not_found")}
      />
    </div>
  );
}
