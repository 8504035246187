import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Integration, Log } from "/app/src/models";
import {
  buildCreationDateQuery,
  getJSON,
  parseOutData,
} from "/app/src/helpers";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import Controls from "./_controls";
import ToolTip from "/app/src/components/generic/components/toolTip";
import LogsTable from "/app/src/components/generic/components/tasks/logsTable";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import ClickableCell from "/app/src/components/generic/components/clickableCell";

/**
 * Component for displaying the logs of a data push integration
 * @param integration the integration to display the logs for
 */
export default function DataPushLogs({
  integration,
}: {
  integration: Integration;
}) {
  const [searchString, setSearchString] = useState("");
  const [beforeTime, setBeforeTime] = useState("");
  const [afterTime, setAfterTime] = useState("");
  const [status, setStatus] = useState<string>("");
  const { t } = useTranslation();
  const [params, setParams] = useState(undefined);
  const navigate = useNavigate();
  useEffect(() => {
    setParams({
      integrationId: integration.id,
      search: searchString,
      creationDate: buildCreationDateQuery(beforeTime, afterTime),
      status,
    });
  }, [searchString, beforeTime, afterTime, status, integration.id]);

  const orderLineClick = useCallback(
    (orderLineId: string) => {
      navigate(`/explorer/order_lines/${orderLineId}`);
    },
    [navigate],
  );
  const transactionIdClick = useCallback(
    (transactionId: string) => {
      navigate(`/explorer/transactions/${transactionId}`);
    },
    [navigate],
  );

  const formatTaskName = useCallback((value: string) => {
    try {
      const parsedData = JSON.parse(value);
      if (parsedData.orderLine) {
        return `Order Line: ${parsedData.orderLine}`;
      } else if (parsedData.transaction) {
        return `Transaction: ${parsedData.transaction}`;
      }
      return `Transaction: ${value}`;
    } catch (e) {
      return `Transaction: ${value}`;
    }
  }, []);

  //Try and parse JSON, if it fails, call transactionIdClick.
  //Otherwise check if the json has a orderLine key and call orderLineClick
  const handleTaskNameClick = useCallback(
    (value: string) => {
      try {
        const parsedData = JSON.parse(value);
        if (parsedData.orderLine) {
          orderLineClick(parsedData.orderLine);
        } else if (parsedData.transaction) {
          transactionIdClick(parsedData.transaction);
        }
      } catch (e) {
        transactionIdClick(value);
      }
    },
    [orderLineClick, transactionIdClick],
  );
  const columnHelper = createColumnHelper<Log>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => <DateTime date={info.getValue()} />,
        header: () => t("translation:creationDate"),
        meta: {
          clickable: false,
        },
      }),
      columnHelper.accessor("time", {
        id: "time",
        cell: (info) => info.getValue(),
        header: () => (
          <>
            {t("translation:time_ms")}
            <ToolTip tip={t("translation:time_description")} />
          </>
        ),
        meta: {
          clickable: false,
        },
      }),
      columnHelper.accessor("taskName", {
        id: "taskName",
        cell: (info) => {
          return (
            <ClickableCell
              cellValue={formatTaskName(info.getValue())}
              clickFn={handleTaskNameClick}
              parameter={info.getValue()}
            />
          );
        },
        header: () => t("translation:id"),
        meta: {
          clickable: false,
        },
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => <p>{parseOutData(info.getValue(), "Sent")}</p>,
        header: () => t("translation:sent_data"),
        meta: {
          clickable: false,
        },
      }),
      columnHelper.accessor("description", {
        id: "response",
        cell: (info) => (
          <p>{getJSON(parseOutData(info.getValue(), "Received"))}</p>
        ),
        header: () => t("translation:response"),
        meta: {
          clickable: false,
        },
      }),
      columnHelper.accessor("error", {
        id: "error",
        cell: (info) => info.getValue(),
        header: () => t("translation:errors"),
        meta: {
          clickable: false,
        },
      }),
      columnHelper.accessor("status", {
        id: "status",
        cell: (info) => info.getValue(),
        header: () => (
          <>
            {t("translation:status")}
            <ToolTip tip={t("translation:status_description")} />
          </>
        ),
        meta: {
          clickable: false,
        },
      }),
    ],
    [columnHelper, formatTaskName, handleTaskNameClick, t],
  );

  return (
    <div>
      <Controls
        setSearchString={setSearchString}
        setBeforeTime={setBeforeTime}
        setAfterTime={setAfterTime}
        setStatus={setStatus}
      />
      {params && (
        <LogsTable
          notFoundText={t("translation:no_logs_found")}
          columns={columns}
          params={params}
        />
      )}
    </div>
  );
}
