import React from "react";

interface IconBuilderProps {
  icon: React.ElementType;
  size?: number;
  color?: string;
  onClick?: () => void;
}

/**
 *
 * @param param0 icon, size and color
 * @returns Icon
 */
const IconBuilder: React.FC<IconBuilderProps> = ({
  icon: Icon,
  size = 24,
  color = "#202023",
  onClick,
}) => {
  return !onClick ? (
    <Icon color={color} size={size} />
  ) : (
    <button onClick={onClick} onKeyDown={onClick}>
      <Icon color={color} size={size} />
    </button>
  );
};

export default IconBuilder;
