import { Row, Col } from "antd";
import NextDivider from "/app/src/components/NextUi/Divider";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import PowerPickChart from "./powerPickChart";
import { useTranslation } from "react-i18next";
import { useConnectionStatusContext } from "/app/src/contexts/hooks/useConnectionStatusContext";
import StatusHook from "/app/src/components/status/statusHook";
import ToolTip from "../../generic/components/toolTip";
import Tip from "/app/src/components/NextUi/Tip";
import ConnectionsList from "/app/src/components/status/connections/connectionsList";
/**
 * Component for displaying connection status
 * @returns JSX.Element
 *
 */
export default function ConnectionStatus() {
  const { t } = useTranslation();
  const { webServices, powerPick } = useConnectionStatusContext();
  const { powerPickMSSQL, powerPickWebServices } = StatusHook();

  return (
    <div className="box">
      <div>
        <Row>
          <Col span={8}>
            <h3 className="chart-title">
              {t("translation:powerpick_mssql")}{" "}
              <ToolTip tip={t("translation:powerpick_chart")} />
            </h3>
          </Col>
          <Col span={16} className="controlButtons">
            {powerPick ? (
              <Tip content={t("translation:connected_powerpick_mssql")}>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ fontSize: 20 }}
                />
              </Tip>
            ) : (
              <Tip content={t("translation:disconnect_powerpick_connection")}>
                <WarningTwoTone
                  twoToneColor="#d0021b"
                  style={{ fontSize: 20 }}
                />
              </Tip>
            )}
          </Col>
        </Row>
        <NextDivider className="my-6" />
        <PowerPickChart data={powerPickMSSQL} />
      </div>
      <div>&nbsp;</div>
      <Row>
        <Col span={8}>
          <h3 className="chart-title" style={{ paddingTop: 20 }}>
            {t("translation:powerpick_web_services")}
            <ToolTip tip={t("translation:powerpick_chart")} />
          </h3>
        </Col>
        <Col span={16} className="controlButtons">
          {webServices ? (
            <Tip content={t("translation:connected_powerpick_web_services")}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: 20 }}
              />
            </Tip>
          ) : (
            <Tip content={t("translation:disconnect_powerpick_web_services")}>
              <WarningTwoTone
                twoToneColor="#d0021b"
                style={{ fontSize: 20, paddingTop: 20 }}
              />
            </Tip>
          )}
        </Col>
      </Row>
      <NextDivider className="my-6" />
      <PowerPickChart data={powerPickWebServices} />
      <div>&nbsp;</div>
      <Row>
        <Col span={24}>
          <h3 className="chart-title" style={{ paddingTop: 20 }}>
            {t("translation:other_connections")}
          </h3>
        </Col>
      </Row>
      <NextDivider className="my-6" />
      <ConnectionsList />
    </div>
  );
}
