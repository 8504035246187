import React, { useReducer } from "react";
import { initialState, AuthReducer } from "./reducer";
import { Action, State } from "./types";
import getEnvironment from "/app/src/helpers/environment";
const AuthStateContext = React.createContext<State>({
  user: { refreshToken: "" },
  isAuthenticated: false,
  loading: true,
  authType:
    getEnvironment().REACT_APP_AZURE_OAUTH_APPLICATION_ID !== ""
      ? "azure"
      : "basic",
});
const AuthDispatchContext = React.createContext<React.Dispatch<Action>>(
  () => null,
);

export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }

  return context;
}

export const AuthProvider = ({ children }: { children: JSX.Element[] }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
