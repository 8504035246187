import React, { useState, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import { connectionService } from "/app/src/services";
import Controls from "./_controls";
import NewIntegration from "./newIntegrationForm";
import { useTranslation } from "react-i18next";
import { App, Connection } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import DeleteButtonCell from "/app/src/components/generic/tables/deleteButtonCell";

/**
 * Component for the Philips Hue app
 * @param app: Philips Hue app
 */
export default function Hue({ app }: { app: App }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [activeNewIntegration, setActiveNewIntegration] = useState(false);

  const { data: connections, refetch } = useQuery({
    queryKey: ["hueConnections", page, pageSize, sort],
    queryFn: () => {
      return connectionService.getAll(
        buildParams({
          limit: pageSize,
          page,
          type: "[or]Hue Local;Hue Remote",
        }),
      );
    },
    initialData: { connections: [] },
    select: (data: { connections: Connection[] }) => {
      return data.connections;
    },
  });

  const { data: connectionsCount, refetch: refetchCount } = useQuery({
    queryKey: ["hueConnectionsCount"],
    queryFn: () => {
      return connectionService.getCount(
        buildParams({ type: "[or]Hue Local;Hue Remote" }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const refreshTable = useCallback(() => {
    refetch();
    refetchCount();
  }, [refetch, refetchCount]);

  const deleteHueConnection = useCallback((id: number) => {
    return connectionService.deleteSingle(id);
  }, []);

  const columnHelper = createColumnHelper<Connection>();
  //columns found in the table that holds the list of connected hubs
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("type", {
        id: "type",
        cell: (info) => info.getValue(),
        header: () => t("translation:access_type"),
      }),
      columnHelper.accessor("id", {
        id: "delete",
        cell: (info) => (
          <DeleteButtonCell
            id={info.getValue()}
            refresh={refreshTable}
            deleteFn={deleteHueConnection}
          />
        ),
        header: "",
        meta: {
          clickable: false,
          sortable: false,
        },
      }),
    ],
    [columnHelper, deleteHueConnection, refreshTable, t],
  );

  return (
    <div className="app epicorImport">
      <Helmet>
        <title>{app.name} - ItemPath</title>
      </Helmet>

      <Controls
        name={app.name}
        activeNewIntegration={activeNewIntegration}
        setActiveNewIntegration={setActiveNewIntegration}
        refresh={refreshTable}
      />
      <Row gutter={20}>
        <Col span={3}>
          <NewIntegration />
        </Col>
        <Col span={21}>
          <TableUpgrade
            rows={connections}
            tableColumns={columns}
            length={connectionsCount}
            sort={sort}
            setSort={setSort}
            enableRowSelection={false}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPage,
              setPageSize,
            }}
            emptyText={t("translation:no_bridges_connected")}
          />
        </Col>
      </Row>
    </div>
  );
}
