import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import RefreshList from "./newSetting";

export default function Controls({
  app,
  setting,
  activeNewSetting,
  toggleNewSetting,
  refresh,
  setActiveNewSetting,
}) {
  return (
    <div className="controls">
      <div className="icon"> </div>

      {setting ? (
        <div className="title">
          <Link to={`/apps/${app.id}`}>{app.name}</Link>
          <span className="separator">{">"}</span>
          <h1>{setting.name}</h1>
        </div>
      ) : (
        <div className="title">
          <h1>{app.name}</h1>
        </div>
      )}

      <div className="controlButtons">
        {setting ? <div /> : ""}
        {activeNewSetting ? (
          <RefreshList
            refresh={refresh}
            newSetting={activeNewSetting}
            setNewSetting={setActiveNewSetting}
          />
        ) : (
          ""
        )}
        <Button type="primary" onClick={() => toggleNewSetting()}>
          New Channel
        </Button>
      </div>
    </div>
  );
}
