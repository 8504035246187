import { DatePicker } from "antd";
import moment from "moment";

export function getDateFormat(
  date: string | undefined,
  format: string,
  timeZone = "utc",
) {
  const utcDate = moment.utc(date);
  //catch invalid timezone - may occur if user timezone is empty string,
  //rather than null
  let formattedDate = utcDate.tz(timeZone);
  if (formattedDate === undefined) {
    formattedDate = utcDate.tz("utc");
  }
  return date && utcDate.isValid() ? formattedDate.format(format) : "";
}

/**
 * Converts a date string to UTC
 */
export function convertTimeZoneToUTC(date: string | Date, timeZone = "utc") {
  const convertedDate = moment.tz(date, timeZone);
  return date && convertedDate.isValid()
    ? convertedDate.tz("utc").format("YYYY-MM-DDTHH:mm:ss")
    : "";
}

export function getTimeZones(): { name: string; id: number }[] {
  const tzList = moment.tz.names();
  return tzList.map((str, index) => ({ name: str, id: index + 1 }));
}

export function reverseDateFormat(
  date: string | undefined,
  format: string,
  timeZone = "utc",
) {
  const formattedDate = moment(date).tz(timeZone);
  const ret = moment.utc(moment(formattedDate)).format(format);
  return ret;
}

/**
 * Takes a before and after date and returns a query string for the creation date
 * using lt and gt operators
 * @param beforeTime the time before which the creation date should be
 * @param afterTime the time after which the creation date should be
 * @returns query string
 */
export function buildCreationDateQuery(beforeTime, afterTime) {
  const creationDate = [];
  if (afterTime !== "") {
    creationDate.push(`[gt]${getDateFormat(afterTime, "YYYY-MM-DDTHH:mm:ss")}`);
  }
  if (beforeTime !== "") {
    creationDate.push(
      `[lt]${getDateFormat(beforeTime, "YYYY-MM-DDTHH:mm:ss")}`,
    );
  }
  return creationDate;
}
type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];

/**
 * Function to handle the change event of the range picker.
 * Sets the before and after time state variables
 * @param value the value of the range picker
 * @param setBeforeTime state setter for before time
 * @param setAfterTime  state setter for after time
 * @returns
 */
export function handleRangePickerChange(
  value: RangeValue,
  setBeforeTime: (time: string) => void,
  setAfterTime: (time: string) => void,
) {
  if (value === null) {
    setAfterTime("");
    setBeforeTime("");
    return;
  }
  if (value[0] !== null) {
    setAfterTime(value[0].toISOString());
  } else {
    setAfterTime("");
  }
  if (value[1] !== null) {
    setBeforeTime(value[1].toISOString());
  } else {
    setBeforeTime("");
  }
}

/**
 *
 * @returns the date 'number' of days ago in YYYY-MM-DD format
 *
 */
export function getPreviousDaysDate(number: number) {
  const currentDate = moment();

  const previousDaysDate = currentDate.clone().subtract(number, "days");

  return previousDaysDate.format("YYYY-MM-DD");
}
