import { Formik } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import ToolTip from "/app/src/components/generic/components/toolTip";
import { Connection as ConnectionType } from "/app/src/models";
import { useCallback } from "react";
import useSetting from "../setting";

/**
 * Component to display test query setting for a single Connection. Used
 * to test the connection to a GraphQL endpoint.
 */
export default function TestQuery({
  connection,
}: {
  connection: ConnectionType;
}) {
  const { t } = useTranslation();

  const { settingValue, createUpdateSetting } = useSetting({
    connectionId: connection.id,
    type: "testQuery",
  });

  const handleSubmit = useCallback(
    async (values: { value: string }) => {
      await createUpdateSetting({
        ...values,
        type: "testQuery",
        name: "GraphQL Test Query",
        connectionId: connection.id,
      });
    },
    [createUpdateSetting, connection.id],
  );
  return (
    <div>
      <Row justify="start">
        <Col>
          <h2>{t("translation:test_query")}</h2>
        </Col>
        <Col>
          <ToolTip tip={t("translation:test_query_tip")} />
        </Col>
      </Row>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            value: settingValue,
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, dirty }) => (
            <Form layout="vertical">
              <Row justify="start" gutter={16}>
                <Col span={24}>
                  <Form.Item name="value" hasFeedback={false}>
                    <Input.TextArea name="value" size="large" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <SubmitButton
                    type="primary"
                    size="large"
                    block
                    disabled={isSubmitting || !dirty}
                  >
                    {t("translation:save")}
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
