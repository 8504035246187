import { useCallback, useMemo, useState } from "react";
import { Formik, useFormikContext } from "formik";
import { Row, Col } from "antd";
import { Form, Select } from "formik-antd";
import {
  ColumnType,
  TextType,
  GroupingType,
  InternalIdType,
  AdvancedType,
} from "./mappingTypes";
import { useTranslation } from "react-i18next";
import { newMappingSchema } from "/app/src/schemas/apps/dataPush/newMappingSchema";
import {
  Integration,
  ReportColumnType,
  Mapping,
  MappingType,
} from "/app/src/models";
import { Error } from "/app/src/types";
import { isSQLType } from "/app/src/helpers";
import { useQueryClient } from "@tanstack/react-query";

/**
 * Component for creating a new mapping. Maps the mapping type to the correct component.
 */
function NewMappingTypes({
  isThemed,
  type,
  columnTypes,
  dirty,
  isValid,
}: {
  isThemed: boolean;
  type: MappingType;
  columnTypes: ReportColumnType[];
  dirty: boolean;
  isValid: boolean;
}) {
  return (
    <>
      {type === "column" && (
        <ColumnType
          isThemed={isThemed}
          columnTypes={columnTypes}
          dirty={dirty}
          isValid={isValid}
        />
      )}
      {type === "advanced" && (
        <AdvancedType
          dirty={dirty}
          isValid={isValid}
          isThemed={isThemed}
          columnTypes={columnTypes}
        />
      )}
      {type === "text" && <TextType dirty={dirty} isValid={isValid} />}
      {["grouping", "data", "lines"].includes(type) && (
        <GroupingType dirty={dirty} />
      )}
      {type === "id" && <InternalIdType dirty={dirty} isValid={isValid} />}
    </>
  );
}

/**
 * Component for creating a new mapping. Maps the mapping type to the correct component.
 */
function MappingForm({
  integration,
  columnTypes,
}: {
  integration?: Integration;
  columnTypes: ReportColumnType[];
}) {
  const { dirty, setFieldValue, isValid } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const [type, setType] = useState<MappingType>(undefined);

  const dataPullMaterialOptions = useMemo(() => {
    return [
      { value: "id", label: t("translation:internal_id") },
      { value: "data", label: t("translation:materials") },
      { value: "column", label: t("translation:column") },
      { value: "text", label: t("translation:text") },
      { value: "grouping", label: t("translation:grouping") },
      { value: "advanced", label: t("translation:advanced") },
    ];
  }, [t]);

  const dataPullSQLOrderOptions = useMemo(() => {
    return [
      { value: "id", label: t("translation:internal_id") },
      { value: "column", label: t("translation:column") },
      { value: "text", label: t("translation:text") },
      { value: "advanced", label: t("translation:advanced") },
    ];
  }, [t]);

  const dataPullOrderOptions = useMemo(() => {
    return [
      { value: "id", label: t("translation:internal_id") },
      { value: "data", label: t("translation:orders") },
      { value: "column", label: t("translation:column") },
      { value: "text", label: t("translation:text") },
      { value: "grouping", label: t("translation:grouping") },
      { value: "lines", label: t("translation:order_lines") },
      { value: "advanced", label: t("translation:advanced") },
    ];
  }, [t]);

  const dataPushOptions = useMemo(() => {
    return [
      { value: "column", label: t("translation:column") },
      { value: "text", label: t("translation:text") },
      { value: "grouping", label: t("translation:grouping") },
    ];
  }, [t]);
  const optionsToMap = useMemo(() => {
    if (integration?.baseTable === "Material") {
      return dataPullMaterialOptions;
    }
    if (integration?.baseTable === "Order") {
      if (isSQLType(integration?.connectionType)) {
        return dataPullSQLOrderOptions;
      }
      return dataPullOrderOptions;
    }
    return dataPushOptions;
  }, [
    integration?.baseTable,
    integration?.connectionType,
    dataPushOptions,
    dataPullMaterialOptions,
    dataPullSQLOrderOptions,
    dataPullOrderOptions,
  ]);

  const changeType = useCallback(
    (value: MappingType) => {
      setType(value);
      setFieldValue("value", "");
      setFieldValue("key", "");
    },
    [setFieldValue],
  );

  return (
    <Form>
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Form.Item name={"type"}>
            <Select
              name={"type"}
              size="large"
              placeholder={t("translation:select_mapping_type")}
              onChange={changeType}
            >
              {optionsToMap.map((option) => (
                <Select.Option
                  key={option.value}
                  value={option.value}
                  label={option.label}
                >
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={18}>
          {type && (
            <NewMappingTypes
              isThemed={Boolean(integration?.baseTable)}
              type={type}
              columnTypes={columnTypes}
              dirty={dirty}
              isValid={isValid}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
}
/**
 * Format the form value keys to match the API Model. Some complex logic
 * is required to handle the different types of columns and text.
 */
export function formatForm(values): Mapping {
  let columnType;
  let value;
  //columns selected from a report will not be an array. Theme columns
  //will be an array(id stored in the 1 index)
  if (Array.isArray(values.columnTypeId)) {
    columnType = values.columnTypeId[1];
    value = values.columnTypeId[0];
  } else {
    value = values?.value;
    columnType = values?.columnTypeId;
  }
  const ret = {
    ...(values?.parentMappingId && {
      parentMappingId: values?.parentMappingId,
    }),
    key: values?.key,
    value,
    type: values?.type,
  };
  if (["column", "advanced"].includes(values.type)) {
    ret["columnTypeId"] = columnType;
  }
  if (values.type === "text") {
    ret.value = values.value;
  }
  if (values.type === "advanced") {
    ret.key = values.key.join(",");
  }
  return ret;
}
interface FormValues {
  key: string | undefined;
  value: MappingType | undefined;
  columnTypeId: number | [string | undefined, number | undefined] | undefined;
  type: MappingType | undefined;
  parentMappingId?: number;
}

/**
 * Component for creating a new mapping.
 */
export default function NewMapping({
  integration,
  addMapping,
  columnTypes,
  parentId = undefined,
}: {
  integration?: Integration;
  addMapping: (mapping: Mapping) => Promise<Error | { mapping: Mapping }>;
  columnTypes: ReportColumnType[];
  parentId?: number | undefined;
}) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const initValues: FormValues = {
    key: undefined,
    value: undefined,
    type: undefined,
    columnTypeId: undefined,
  };

  /**
   * Handle the form submit. Format the form values and call the addMapping
   * function.
   */
  const handleSubmit = useCallback(
    async (values, actions) => {
      values["parentMappingId"] = parentId;
      const data = formatForm(values);
      data["integrationId"] = integration.id;
      const { mappings }: { mappings: Mapping[] } = queryClient.getQueryData([
        "mappings",
        integration.id,
      ]);

      if (["id", "data"].includes(data.type)) {
        //check if any mappings already exist with a type of id - only one is allowed
        const mapping = mappings?.find(
          (mapping: Mapping) => mapping.type === data.type,
        );
        if (mapping) {
          actions.setFieldError(
            "type",
            t("translation:mapping_type_already_exists"),
          );
          return;
        }
      }
      await addMapping(data).finally(() => {
        actions.resetForm();
      });
    },
    [addMapping, integration.id, parentId, queryClient, t],
  );
  return (
    <Formik
      initialValues={initValues}
      validationSchema={newMappingSchema}
      onSubmit={handleSubmit}
    >
      <MappingForm integration={integration} columnTypes={columnTypes} />
    </Formik>
  );
}
