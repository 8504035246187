import { string, array, object, mixed } from "yup";
import i18next from "/app/src/locales/config";

export const newMappingSchema = object().shape({
  type: string().required(i18next.t("translation:type_is_required")),
  key: mixed().required(i18next.t("translation:key_required")),
  columnTypeId: array().when("type", {
    is: (type) => type === "column",
    then: (schema) =>
      schema
        .min(1, i18next.t("translation:required"))
        .required(i18next.t("translation:column_required")),
  }),
  value: string().when("type", {
    is: (type) => type === "text",
    then: (schema) => schema.required(i18next.t("translation:value_required")),
  }),
});
