import { useCallback } from "react";
import NextPagination from "/app/src/components/NextUi/Pagination";
import { useTranslation } from "react-i18next";
import NextSelect from "/app/src/components/NextUi/Select";

export default function Paginate({
  length,
  pageSize,
  setPageSize,
  currentPage,
  setPage,
}) {
  const { t } = useTranslation();

  //divide length by pageSize to get the total number of pages
  const totalPages = Math.ceil(length / pageSize);

  // Handler to change the page
  const handlePageChange = useCallback(
    (newPage: number) => {
      setPage(newPage - 1); // Convert newPage from one-based to zero-based index
    },
    [setPage],
  );

  // Handler to change the page size
  const handlePageSizeChange = useCallback(
    (event) => {
      setPageSize(parseInt(event.target.value));
    },
    [setPageSize],
  );

  // Calculate the range of items displayed on the current page
  const calculateRange = () => {
    if (length === 0) {
      return "0-0 of 0 items";
    }
    const startIndex = currentPage * pageSize + 1;
    const endIndex = Math.min((currentPage + 1) * pageSize, length);
    return `${startIndex}-${endIndex} of ${length} items`;
  };

  return (
    <div className="grid grid-cols-1 pt-2">
      {/* Display the range of items */}
      <div className="col-span-1 flex justify-center items-center">
        <h3 className="pr-4 mt-1">{calculateRange()}</h3>

        {/* Only render the pagination component if the length is non-negative*/}
        {totalPages > 0 && (
          <>
            {/* Render the NextPagination component */}
            <NextPagination
              total={totalPages}
              page={currentPage + 1} // Convert currentPage from zero-based to one-based index
              onChange={handlePageChange}
              initialPage={currentPage + 1} // Convert currentPage from zero-based to one-based index
              className="mt-1"
            />

            {/* Page size input using NextUI Select component */}
            <NextSelect
              items={[
                { key: "10", value: "10", label: t("translation:10/page") },
                { key: "20", value: "20", label: t("translation:20/page") },
                { key: "25", value: "25", label: t("translation:25/page") },
                { key: "50", value: "50", label: t("translation:50/page") },
                { key: "100", value: "100", label: t("translation:100/page") },
              ]}
              size="md"
              value={pageSize.toString()} // Convert pageSize to string for compatibility with Select
              onChange={handlePageSizeChange}
              defaultSelectedKeys={[pageSize.toString()]}
              className="w-40 pl-4"
              ariaLabel="Items per page"
              disallowEmptySelection
            />
          </>
        )}
      </div>
    </div>
  );
}
