// https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript?rq=1

import React, { useContext } from "react";
import Moment from "react-moment";
import "moment-timezone";
import TimeZoneContext from "/app/src/contexts/TimeZoneContext";
import { convertTimeZoneToUTC } from "/app/src/helpers/time";

export default function ConvertToLocalTimezone({
  date = "",
  format = "dddd, MMMM Do YYYY, h:mm a",
}: {
  date?: string | Date;
  format?: string;
}) {
  const { timeZone } = useContext(TimeZoneContext);

  return (
    <Moment format={format} utc tz={timeZone}>
      {date}
    </Moment>
  );
}

/**
 * Converts the powerpick date to the local timezone
 */
export function PowerPickTimeZoneToLocal({
  date = "",
  format = "dddd, MMMM Do YYYY, h:mm a",
}: {
  date?: string | Date;
  format?: string;
}) {
  const { powerPickTimeZone, timeZone } = useContext(TimeZoneContext);
  //adjust the powerpick timezone to utc, then local timezone
  if (!date) {
    return null;
  }
  return (
    <Moment format={format} utc tz={timeZone}>
      {convertTimeZoneToUTC(date, powerPickTimeZone)}
    </Moment>
  );
}
