import { useCallback } from "react";
import { settingService } from "/app/src/services";
import { Setting as SettingType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

/**
 * Custom hook for the Setting component
 * @param params
 * @returns
 */
export default function useSetting(params) {
  const queryClient = useQueryClient();

  const { data: setting } = useQuery({
    queryKey: ["settings", params],
    queryFn: () => {
      return settingService.getAll(buildParams(params)).then((response) => {
        return { setting: response.settings?.[0] };
      });
    },
    initialData: { setting: {} as SettingType },
    select: (data) => {
      if (data.setting) {
        return data.setting;
      }
      return undefined;
    },
  });

  const { mutateAsync: createSetting } = useMutation({
    mutationFn: (values: SettingType) => {
      return settingService.createSingle(values).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["settings", params], response);
    },
  });

  const { mutateAsync: updateSetting } = useMutation({
    mutationFn: (values: SettingType) => {
      return settingService
        .updateSingle(setting.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["settings", params], response);
    },
  });

  const createUpdateSetting = useCallback(
    async (values) => {
      if (setting) {
        await updateSetting(values);
      } else {
        await createSetting(values);
      }
    },
    [setting, createSetting, updateSetting],
  );

  return {
    settingValue: setting ? setting.value : undefined,
    createUpdateSetting,
  };
}
