import React, { useCallback, useState } from "react";
import { Col, Row } from "antd";
import { Form, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";
import File from "./file";
import CSV from "./csv";
import { PaperClipOutlined } from "@ant-design/icons";
import { appService } from "/app/src/services";
import { WorkflowAction, App, Report } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { attachmentSchema } from "/app/src/schemas/workflows/apps/attachmentSchema";
import { useQuery } from "@tanstack/react-query";
interface NewFormValues {
  app?: string;
  reportId?: number;
  value?: string | number;
  to?: string | number;
  integrationId?: number;
}

/**
 * Format the form values for the update action
 */
function formatUpdateForm(values: NewFormValues) {
  const ret = {
    reportId: values.reportId,
    value: values.value,
    to: values.to,
  };
  if (values.integrationId !== null) {
    ret["integrationId"] = values.integrationId;
  }
  return ret;
}

/**
 * Format the form values for the new action
 */
function formatForm(
  values: NewFormValues,
  parentActionId: number | undefined,
  workflowId: number | undefined,
) {
  const ret: WorkflowAction = {
    reportId: values.reportId,
    value: values.value,
    to: values.to,
    parentActionId,
    workflowId,
  };
  if (values.integrationId !== null) {
    ret["integrationId"] = values.integrationId;
  }
  if (values.app) {
    const appId = JSON.parse(values.app).id as number;
    ret["appId"] = appId;
  }
  return ret;
}

export default function Attachments({
  parentAction,
  reports,
  attachments,
  addAttachment,
  removeAttachment,
  updateAttachment,
}: {
  parentAction: WorkflowAction;
  reports: Report[];
  attachments: WorkflowAction[];
  addAttachment: (attachment: WorkflowAction) => void;
  removeAttachment: (action: WorkflowAction) => void;
  updateAttachment: (
    oldAction: WorkflowAction,
    workflowAction: WorkflowAction,
  ) => void;
}) {
  const { t } = useTranslation();
  const [selectedApp, setSelectedApp] = useState<App>({});

  //get the file attachment apps and reports
  const { data: apps } = useQuery({
    queryKey: ["apps", "[or]CSV;XLSX;PDF"],
    queryFn: () => {
      return appService.getAll(buildParams({ name: "[or]CSV;XLSX;PDF" }));
    },
    initialData: { apps: [] },
    select: (data: { apps: App[] }) => {
      return data.apps;
    },
  });

  const getAppName = useCallback(
    (appId: number) => {
      const app = apps.find((a) => a.id === appId);
      return app ? app.name : "";
    },
    [apps],
  );

  const handleSubmission = useCallback(
    (values: NewFormValues, actions) => {
      addAttachment(
        formatForm(values, parentAction.id, parentAction.workflowId),
      );
      actions.setSubmitting(false);
      actions.resetForm();
      setSelectedApp({});
    },
    [addAttachment, parentAction.id, parentAction.workflowId],
  );

  const initialFormValues: NewFormValues = {
    app: undefined,
    reportId: undefined,
    value: "",
    to: "",
    integrationId: undefined,
  };
  const newAttachmentForm: (props: FormikProps<NewFormValues>) => JSX.Element =
    useCallback(
      ({ dirty, setFieldValue }) => (
        <Form>
          <Row justify="start" gutter={16}>
            <Col span={24}>
              <Row justify="start" gutter={16}>
                <Col span={4}>
                  <Form.Item name="app">
                    <Select
                      name="app"
                      size="large"
                      placeholder={t("translation:select_app")}
                      onChange={(value) => {
                        setSelectedApp(JSON.parse(value));
                        setFieldValue("integrationId", undefined);
                      }}
                    >
                      {apps.map((c) => (
                        <Select.Option key={c.id} value={JSON.stringify(c)}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {selectedApp.name === "XLSX" && (
                  <File reports={reports} isNew dirty={dirty} />
                )}
                {selectedApp.name === "CSV" && (
                  <CSV
                    appId={selectedApp.id}
                    reports={reports}
                    isNew
                    dirty={dirty}
                  />
                )}
                {selectedApp.name === "PDF" && (
                  <File reports={reports} isNew dirty={dirty} />
                )}
              </Row>
            </Col>
          </Row>
        </Form>
      ),
      [apps, reports, selectedApp.id, selectedApp.name, t],
    );

  return (
    <>
      <Row justify="start" gutter={16}>
        {attachments.map((action) => (
          <Col
            span={24}
            key={action.appId + action.integrationId + action.reportId}
          >
            <Row>
              <Col span={2}>
                <PaperClipOutlined
                  style={{ fontSize: "28px", color: "#1890ff" }}
                />
              </Col>
              <Col span={22}>
                <Formik
                  initialValues={{
                    appId: action.appId,
                    reportId: action.reportId,
                    value: action.value,
                    to: action.to,
                    integrationId: action.integrationId,
                  }}
                  enableReinitialize
                  onSubmit={(values, actions) => {
                    updateAttachment(action, formatUpdateForm(values));
                    actions.setSubmitting(false);
                    actions.resetForm();
                  }}
                >
                  {({ dirty }) => (
                    <Form>
                      <Row justify="start" gutter={16}>
                        <Col span={24}>
                          <Row justify="start" gutter={16}>
                            <Col span={3}>
                              <span className="blockText">
                                {getAppName(action.appId)}
                              </span>
                            </Col>

                            {getAppName(action.appId) === "XLSX" && (
                              <File
                                reports={reports}
                                isNew={false}
                                dirty={dirty}
                                action={action}
                                removeWorkflowAction={removeAttachment}
                              />
                            )}
                            {getAppName(action.appId) === "CSV" && (
                              <CSV
                                appId={action.appId}
                                reports={reports}
                                isNew={false}
                                dirty={dirty}
                                action={action}
                                removeWorkflowAction={removeAttachment}
                              />
                            )}
                            {getAppName(action.appId) === "PDF" && (
                              <File
                                reports={reports}
                                isNew={false}
                                dirty={dirty}
                                action={action}
                                removeWorkflowAction={removeAttachment}
                              />
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <h3>Add a file attachment:</h3>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <Formik
            initialValues={initialFormValues}
            component={newAttachmentForm}
            enableReinitialize
            onSubmit={handleSubmission}
            validationSchema={attachmentSchema}
          />
        </Col>
      </Row>
    </>
  );
}
