import { ExclamationCircleTwoTone } from "@ant-design/icons";
import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";

export default function ModalBuilder({
  warning,
  modalHeader,
  modalBody,
  modalFooter,
}: {
  warning?: boolean;
  modalHeader?: string;
  modalBody?: string;
  modalFooter?: React.ReactNode;
}) {
  return (
    <>
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center pr-2">
            {warning && <ExclamationCircleTwoTone twoToneColor="#faad14" />}{" "}
          </div>
          {modalHeader}
        </ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter>{modalFooter}</ModalFooter>
      </ModalContent>
    </>
  );
}
