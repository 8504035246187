import React from "react";
import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { Theme as ThemeType } from "/app/src/types";
export default function Filters({
  themes,
  setSearchString,
  setSelectedTheme,
  setSelectedType,
  types,
}: {
  themes?: ThemeType[];
  setSearchString: (searchString: string) => void;
  setSelectedTheme: (selectedTheme: string) => void;
  setSelectedType: (selectedType: string) => void;
  types: ThemeType[];
}) {
  return (
    <div className="integrationListFilters">
      <Search setSearchString={setSearchString} />
      {themes && <Theme themes={themes} setSelectedTheme={setSelectedTheme} />}
      {types.length > 0 && (
        <Type setSelectedType={setSelectedType} types={types} />
      )}
    </div>
  );
}

export function Search({
  setSearchString,
}: {
  setSearchString: (searchString: string) => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="search box">
      <Input.Search
        onSearch={(value) => setSearchString(value)}
        size="large"
        placeholder={t("translation:search")}
      />
    </div>
  );
}

export function Theme({
  themes,
  setSelectedTheme,
}: {
  themes: ThemeType[];
  setSelectedTheme: (selectedTheme: string) => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="themeFilter box">
      <Select
        defaultValue="all"
        size="large"
        style={{ width: "100%" }}
        onChange={(value) => setSelectedTheme(value)}
        placeholder={t("translation:select_theme")}
      >
        <Select.Option value="all">{t("translation:all_themes")}</Select.Option>
        {themes.map((theme, index) => (
          <Select.Option key={index} value={theme.value}>
            {theme.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export function Type({
  setSelectedType,
  types,
}: {
  setSelectedType: (selectedType: string) => void;
  types: ThemeType[];
}) {
  const { t } = useTranslation();
  return (
    <div className="themeFilter box">
      <Select
        defaultValue="all"
        size="large"
        style={{ width: "100%" }}
        onChange={(value) => setSelectedType(value)}
        placeholder={t("translation:select_type")}
      >
        <Select.Option value="all">{t("translation:all_types")}</Select.Option>
        {types.map((theme, index) => (
          <Select.Option key={index} value={theme.value}>
            {theme.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
