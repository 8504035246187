import { iOrder as iOrderType } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";
import { iOrderService } from "/app/src/services";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Line from "./line";
import Revisions from "./revisions";

interface FormValues {
  name?: string;
  directionType?: string;
  Info1?: string;
  Info2?: string;
  Info3?: string;
  Info4?: string;
  Info5?: string;
  priority?: string;
  warehouseName?: string;
}

/**
 * Formats the form values to be sent to the backend API
 */
function formatForm(values: FormValues) {
  const ret = {
    name: values.name,
    directionType: values.directionType,
    Info1: values.Info1,
    Info2: values.Info2,
    Info3: values.Info3,
    Info4: values.Info4,
    Info5: values.Info5,
    priority: values.priority,
    warehouseName: values.warehouseName,
  };
  //json stringify the object
  return { data: JSON.stringify(ret) };
}

/**
 * Displays the order details of an order created through the Order Builder App
 */
export default function OrderBuildOrder() {
  const { orderId } = useNumberId();
  const { t } = useTranslation();
  const { data: iOrder } = useQuery({
    queryKey: ["order", orderId],
    queryFn: () => {
      return iOrderService.getSingle(orderId);
    },
    initialData: { order: { lines: [] } },
    select: (data: { order: iOrderType }) => {
      return data.order;
    },
  });
  const queryClient = useQueryClient();
  const saveOrder = useCallback(
    async (values: FormValues) => {
      await iOrderService
        .updateSingle(orderId, formatForm(values))
        .then((response) => {
          queryClient.setQueryData(["order", orderId], response);
        });
    },
    [orderId, queryClient],
  );
  const newOrderForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <Form.Item name="name" label={t("translation:name")}>
                <Input
                  suffix
                  name="name"
                  placeholder={t("translation:enter_name")}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="directionType"
                label={t("translation:direction_type")}
              >
                <Input
                  name="directionType"
                  placeholder={t("translation:test")}
                  size="large"
                />
              </Form.Item>
            </Col>

            {["info1", "info2", "info3", "info4", "info5"].map(
              (info: "info1" | "info2" | "info3" | "info4" | "info5") => (
                <Col span={8} key={info}>
                  <Form.Item
                    name={info.charAt(0).toUpperCase() + info.slice(1)}
                    label={t(`translation:${info}`)}
                  >
                    <Input
                      name={info.charAt(0).toUpperCase() + info.slice(1)}
                      placeholder={t(`translation:${info}`)}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              ),
            )}

            <Col span={8}>
              <Form.Item name="priority" label={t("translation:priority")}>
                <Input
                  name="priority"
                  placeholder={t("translation:test")}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="warehouseName"
                label={t("translation:warehouse_name")}
              >
                <Input
                  name="warehouseName"
                  placeholder={t("translation:test")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="creationDate"
                label={t("translation:creation_date")}
              >
                <Input name="creationDate" size="large" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="orderStatus" label={t("translation:status")}>
                <Input name="orderStatus" size="large" disabled />
              </Form.Item>
            </Col>

            <Col span={4} offset={4}>
              <SubmitButton
                style={{ marginTop: "30px" }}
                type="primary"
                size="large"
                block
                disabled={!(dirty && isValid)}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  const getInitValues = useCallback(() => {
    let orderData: FormValues = {};
    try {
      orderData = JSON.parse(iOrder.data);
      if (typeof orderData === "string") {
        orderData = JSON.parse(orderData);
      }
    } catch (e) {
      orderData = {};
    }
    return {
      name: orderData?.name,
      directionType: orderData?.directionType,
      Info1: orderData?.Info1,
      Info2: orderData?.Info2,
      Info3: orderData?.Info3,
      Info4: orderData?.Info4,
      Info5: orderData?.Info5,
      priority: orderData?.priority,
      warehouseName: orderData?.warehouseName,
      creationDate: iOrder.creationDate,
      status: iOrder.orderStatus,
      orderStatus: iOrder.orderStatus,
    };
  }, [iOrder]);
  return (
    <>
      <div className="box">
        <Formik
          enableReinitialize
          component={newOrderForm}
          initialValues={getInitValues()}
          onSubmit={saveOrder}
        />
      </div>

      {iOrder?.lines.length > 0 && <h2>{t("translation:lines")}</h2>}
      {iOrder?.lines.map((line) => <Line key={line.id} line={line} />)}
      <h2>{t("translation:history")}</h2>
      <Revisions order={iOrder} />
    </>
  );
}
