import React, { useCallback, useMemo } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, InputNumber } from "formik-antd";
import { criteriaSchema } from "/app/src/schemas";
import { useTranslation } from "react-i18next";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { WorkflowCriteria, Workflow, Report } from "/app/src/models";

interface FormValues {
  reportId: number | undefined;
  verb: string | undefined;
  number: string | undefined;
}

export default function NewCriteria({
  workflow,
  addWorkflowCriteria,
  reports,
}: {
  workflow: Workflow;
  addWorkflowCriteria: (workflowCriteria: WorkflowCriteria) => Promise<any>;
  reports: Report[];
}) {
  const { t } = useTranslation();
  const verbs = useMemo(
    () => [
      { name: "equals", value: "eq" },
      { name: "not", value: "not" },
      { name: "greater than", value: "gt" },
      { name: "less than", value: "lt" },
      { name: "greater or equal", value: "ge" },
      { name: "less or equal", value: "le" },
    ],
    [],
  );

  const newCriteriaForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form>
          <Row justify="start" align="middle" gutter={16}>
            <Col span={5}>
              <h4>{t("translation:report")}</h4>
              <Form.Item name="reportId">
                <Select
                  name="reportId"
                  size="large"
                  placeholder={t("translation:select_report")}
                >
                  {reports.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <span className="blockText">{t("translation:row_count")}</span>
            </Col>
            <Col span={5}>
              <h4>{t("translation:comparison")}</h4>
              <Form.Item name="verb">
                <Select
                  name="verb"
                  size="large"
                  placeholder={t("translation:select_comparison")}
                >
                  {verbs.map((c) => (
                    <Select.Option key={c.value} value={c.value}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <h4>{t("translation:value")}</h4>
              <Form.Item name="value" hasFeedback={false}>
                <InputNumber name="value" min={0} max={1000000} size="large" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <SubmitButton size="large" block disabled={!dirty}>
                {t("translation:add")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, reports, verbs],
    );
  const initialValues: FormValues = {
    reportId: undefined,
    verb: undefined,
    number: undefined,
  };
  return (
    <div className="newWorkflowCriteria">
      <h3>
        {t("translation:add")} {t("translation:criteria")}
      </h3>
      <Formik
        component={newCriteriaForm}
        initialValues={initialValues}
        validationSchema={criteriaSchema}
        onSubmit={(values, actions) => {
          const newCriteria = {
            ...values,
            workflowId: workflow.id,
          } as WorkflowCriteria;
          addWorkflowCriteria(formatForm(newCriteria)).then((response) => {
            if (response?.errors) {
              handleSubmissionErrors(response.errors, actions.setFieldError);
              actions.setSubmitting(false);
            } else {
              actions.resetForm();
            }
          });
        }}
      />
    </div>
  );
}
function formatForm(values: WorkflowCriteria) {
  return {
    workflowId: values.workflowId,
    reportId: values.reportId,
    value: values.value,
    verb: values.verb,
  };
}
