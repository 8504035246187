import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { materialPropertyService } from "/app/src/services";

import Search from "../search";
import getOrderByQuery from "/app/src/helpers/table";

import { useTranslation } from "react-i18next";
import { MaterialProperty as MaterialPropertyType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

export default function MaterialProperties() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const columnHelper = createColumnHelper<MaterialPropertyType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
      columnHelper.accessor("expiryDatePickType", {
        id: "expiryDatePickType",
        cell: (info) => info.getValue(),
        header: t("translation:expiry"),
      }),
    ],
    [columnHelper, t],
  );

  const goToMaterialProperty = (materialPropertyId: number) => {
    navigate(`/explorer/material_properties/${materialPropertyId}`);
  };

  //Gets all material properties
  const { data: materialProperties, isFetching } = useQuery({
    queryKey: ["materialProperties", page, pageSize, searchString, sort],
    queryFn: () => {
      return materialPropertyService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { material_properties: [] },
    select: (data: { material_properties: MaterialPropertyType[] }) => {
      return data.material_properties;
    },
  });

  //Gets count of all material properties
  const { data: materialPropertiesCount } = useQuery({
    queryKey: ["materialPropertiesCount", searchString],
    queryFn: () => {
      return materialPropertyService.getCount(
        buildParams({ search: searchString }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:material_properties")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <TableUpgrade
        loading={isFetching}
        rows={materialProperties}
        tableColumns={columns}
        length={materialPropertiesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        enableRowSelection
        rowClicked={goToMaterialProperty}
        emptyText={`${t("translation:no")} ${t(
          "translation:material_properties",
        )} ${t("translation:found")}`}
      />
    </div>
  );
}
