import React, { useState, useEffect } from "react";
import ReactGridLayout, { Responsive, WidthProvider } from "react-grid-layout";
import { widgetService } from "/app/src/services";
import Widget from "./widget";
import { Widget as WidgetType } from "/app/src/models";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function WidgetArea({
  widgets,
  locked,
  isEditing,
}: {
  widgets: WidgetType[];
  locked: boolean;
  isEditing: boolean;
}) {
  const compactType = "vertical";
  const [currentBreakpoint, setCurrentBreakpoint] = useState<
    "md" | "lg" | "sm" | "xs" | "xxs" | undefined
  >(undefined);
  const [isMounted, setIsMounted] = useState(false);
  const [layouts, setLayouts] = useState<ReactGridLayout.Layouts>();
  const cols = React.useMemo(() => {
    return {
      lg: 12,
      md: 10,
      sm: 6,
      xs: 4,
      xxs: 3,
    };
  }, []);
  //default height and width of new widgets, also sets min size for all widgets
  const defaultW = 3;
  const defaultH = 2;
  //when the window width changes, the number of columns needs to change
  const onBreakpointChange = (
    breakpoint: "md" | "lg" | "sm" | "xs" | "xxs",
  ) => {
    setCurrentBreakpoint(breakpoint);
  };

  //only load the grid once the component has mounted
  useEffect(() => {
    setIsMounted(true);
  }, []);

  //when the layout changes(a widget moves), the widgets position column needs
  //to be updated
  const onLayoutChange = (
    layout: ReactGridLayout.Layout[],
    layouts: ReactGridLayout.Layouts,
  ) => {
    if (currentBreakpoint === undefined) {
      //the current breakpoint is undefined when the page first loads
      //set the breakpoint to the first key in the layout object
      setCurrentBreakpoint(
        Object.keys(layouts)[0] as "md" | "lg" | "sm" | "xs" | "xxs",
      );
    }
    setLayouts(layouts);
    //convert the object into array
    const objectArray = Object.entries(layout);
    objectArray.forEach(([_key, value]) => {
      const position = computePosition(value);

      //update position for widget object in front end
      for (const w of widgets) {
        if (w.id === parseInt(value["i"]) && w.position !== position) {
          w.position = position;
          //update position for widget in db if the user is editing the layout
          if (!locked) {
            widgetService.updateSingle(value["i"], { position });
          }
        }
      }
    });
  };

  //Go through each widget and calculate its position on the grid.
  const generateDOM = () => {
    if (widgets) {
      return widgets.map((widget) => {
        //if the widget is new, there will be no position value
        let grid = { w: defaultW, h: defaultH, x: 0, y: 0 };
        if (widget.position) {
          grid = parsePosition(widget);
        }
        //new widgets will go here to calculate their position
        else {
          grid = calculateNewPosition(layouts, currentBreakpoint, cols);
          //update widget position
          widgetService.updateSingle(widget.id, {
            position: `${grid.w}-${grid.h}-${grid.x}-${grid.y}`,
          });
        }

        return (
          <div key={widget.id} className="widgetBox widget" data-grid={grid}>
            <Widget
              key={widget.id}
              widget={widget}
              locked={locked}
              isEditing={isEditing}
            />
          </div>
        );
      });
    }
  };

  return (
    <div className="layout">
      <ResponsiveReactGridLayout
        className="layout" // skipcq: JS-0394
        rowHeight={200}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChange}
        cols={cols}
        layouts={layouts}
        measureBeforeMount={false}
        useCSSTransforms={isMounted}
        compactType={compactType}
        preventCollision={!compactType}
        isResizable={!locked && !isEditing}
        isDraggable={!locked && !isEditing}
      >
        {generateDOM()}
      </ResponsiveReactGridLayout>
    </div>
  );
}

//the position of the widget is stored in the db as "w-h-x-y". This string
//needs to be converted into an object with those 4 properties
function parsePosition(widget: WidgetType, defaultH = 2, defaultW = 3) {
  //add checks for valid position format
  //error will throw if a number is in the widget position
  let values = [];
  if (widget.position) {
    values = widget.position.split("-");
  } else {
    return { w: defaultW, h: defaultH, x: 0, y: 0 };
  }
  if (values.length === 4) {
    return {
      w: parseInt(values[0]),
      h: parseInt(values[1]),
      x: parseInt(values[2]),
      y: parseInt(values[3]),
      maxH: 6,
      minH: defaultH,
      minW: defaultW,
    };
  } else {
    return { w: defaultW, h: defaultH, x: 0, y: 0 };
  }
}

//React Grid Layout stores each widget's position as an object with 4 properties.
//This function converts the object to a string to store in the widget.position column
function computePosition(position: ReactGridLayout.Layout) {
  const coords = [position["w"], position["h"], position["x"], position["y"]];
  return coords.join("-");
}
/**
 * Function to return the position of a new widget. The position is calculated
 * based on the position of the other widgets in the grid.
 * @param layouts Array of widget positions in the grid
 * @param currentBreakpoint Current screen size
 * @param cols Number of columns in the grid for the current screen size
 * @param defaultH default height of a widget
 * @param defaultW default width of a widget
 * @returns Object with the position of the new widget
 */
function calculateNewPosition(
  layouts: ReactGridLayout.Layouts,
  currentBreakpoint: "md" | "lg" | "sm" | "xs" | "xxs",
  cols: {
    lg: number;
    md: number;
    sm: number;
    xs: number;
    xxs: number;
  },
  defaultH = 2,
  defaultW = 3,
) {
  if (!layouts) {
    //no widgets so put at starting position
    return {
      w: defaultW,
      h: defaultH,
      x: 0,
      y: 0,
      minH: defaultH,
      minW: defaultW,
    };
  }

  const gridWidth = cols[currentBreakpoint];
  //grab the positions given the current breakpoint -> screensize/responsiveness
  const positions = layouts[currentBreakpoint];
  const maxX = gridWidth - defaultW;

  //the variables used to track the x and y of the new widget
  let xCoord = 0;
  let yCoord = 0;

  //the foor loop will exit once an empty spot is found
  for (let i = 0; i < positions.length; i++) {
    //loop through the current positions of the other widgets, looking for a widget that is blocking the current position
    //Note: the default widget size is 3x2, so must account for that (using y+1 + and x +1)
    const position = positions[i];
    if (
      position.y <= yCoord + (defaultH - 1) &&
      yCoord < position.y + position.h &&
      position.x <= xCoord + (defaultW - 1) &&
      xCoord < position.x + position.w
    ) {
      //we've found a widget that is blocking the current spot, so adjust x to the end of the found widget, then
      //set i to 0 to loop back through the widgets again(setting to -1 due to for loop increment)
      xCoord = position.x + position.w;
      i = -1;

      if (xCoord > maxX) {
        //we've hit the end of the row, set x = 0 and increment y to go
        //to next row
        xCoord = 0;
        yCoord += 1;
      }
    }
  }
  //return the new coordinates of the widget, using the x and y values found
  return {
    w: defaultW,
    h: defaultH,
    x: xCoord,
    y: yCoord,
    minH: defaultH,
    minW: defaultW,
  };
}
