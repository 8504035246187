import { useParams } from "react-router-dom";

//create a type that matches the params object but is not read only
type Params<Key extends string = string> = {
  [key in Key]: string | number | undefined;
};

/**
 * This hook is used to convert the params object into a new object where all
 * values are numbers - excluding wildcards. This is useful when you need to use the params object
 * to make a request to an API that requires a number.
 *
 * THIS SHOULDN'T BE USED FOR UUID's
 */
export function useNumberId() {
  const params: Params = useParams();
  //create copy of params object
  const paramsCopy = { ...params };

  Object.keys(paramsCopy).forEach((key) => {
    if (key !== "*") {
      paramsCopy[key] = Number(paramsCopy[key]);
    }
  });

  return paramsCopy;
}
