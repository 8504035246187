import { locationContentService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers/params";
import {
  LocationContent as LocationContentType,
  Material,
} from "/app/src/models";

export default function LocationContents({ material }: { material: Material }) {
  const { t } = useTranslation();

  const { data: locationContents = [] } = useQuery({
    queryKey: ["locationContentsByMaterial", material.id],
    queryFn: () => {
      return locationContentService.getAll(
        buildParams({ materialId: material.id }),
      );
    },
    enabled: Boolean(material?.id),
    initialData: { contents: [] },
    select: (data: { contents: LocationContentType[] }) => {
      return data.contents;
    },
  });

  return (
    <div className="box" id="location_contents">
      <h3>{t("translation:locations")}</h3>

      {locationContents.length > 0 ? (
        <ul>
          {locationContents.map((locationContent) => (
            <li>
              {locationContent.locationName}
              <span key={locationContent.id} className="quantity">
                {locationContent.currentQuantity}
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <div />
      )}
    </div>
  );
}
