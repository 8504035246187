import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import { settingService } from "/app/src/services";
import Delete from "./deleteIntegration";
import Table from "/app/src/components/generic/tables/table";
import Controls from "./_controls";
import Filters from "./filters";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";

export default function SlackApp({ app }) {
  const { t } = useTranslation();
  const [settings, setSettings] = useState([]);
  const [length, setLength] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [activeNewSetting, setActiveNewSetting] = useState(false);

  //refresh the list of connect channels
  const refreshTable = React.useCallback(() => {
    settingService
      .getAll(
        buildParams({
          limit: pageSize,
          page,
          name: searchString,
          appId: app.id,
        }),
      )
      .then((response) => setSettings(response.settings));
    settingService
      .getAll(buildParams({ countOnly: true, appId: app.id }))
      .then((response) => setLength(response.count));
  }, [page, pageSize, searchString, app]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: t("name"),
        accessor: "name",
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => (
          <Delete settingId={value} appId={app.id} refresh={refreshTable} />
        ),
      },
    ];
  }, [t, app.id, refreshTable]);

  //Open up a new tab for connecting a slack channel
  const toggleNewSetting = () => {
    let path = "";
    if (typeof window !== "undefined") {
      path = `${window.location.protocol}//${window.location.host}/api/settings/slack`;
    } else {
      console.log("undefined window");
    }
    window.open(
      `https://slack.com/oauth/v2/authorize?client_id=1668636947058.1710819905905&scope=channels:join,chat:write,channels:read,incoming-webhook&redirect_uri=${path}`,
    );
    setActiveNewSetting(!activeNewSetting);
  };

  // Get number of connected channels
  useEffect(() => {
    settingService
      .getAll(buildParams({ countOnly: true, appId: app.id }))
      .then((response) => setLength(response.count));
  }, [app]);

  //get a list of connected channels
  useEffect(() => {
    settingService
      .getAll(
        buildParams({
          limit: pageSize,
          page,
          name: searchString,
          appId: app.id,
        }),
      )
      .then((response) => setSettings(response.settings));
  }, [page, pageSize, searchString, app]);

  return (
    <div className="app" id="slack">
      <Helmet>
        <title>{app.name} - ItemPath</title>
      </Helmet>

      <Controls
        app={app}
        toggleNewSetting={toggleNewSetting}
        activeNewSetting={activeNewSetting}
        setActiveNewSetting={setActiveNewSetting}
        refresh={refreshTable}
      />
      <Row gutter={20}>
        <Col span={6}>
          <Filters setSearchString={setSearchString} />
        </Col>
        <Col span={18}>
          <Table
            rows={settings}
            tableColumns={columns}
            length={length}
            sort={sort}
            setSort={setSort}
            currentPage={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            emptyText="No Workspaces Connected"
          />
        </Col>
      </Row>
    </div>
  );
}
