import React, { useState } from "react";

import { useLogs } from "./data/useLogs";
import getOrderByQuery from "/app/src/helpers/table";
import { DefaultColumns } from "./data/defaultColumns";
import { Log } from "/app/src/models";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { ColumnDef } from "@tanstack/react-table";
/**
 * Shows logs for a task
 * @param param0 object containing taskId, mappings, notFoundText and notSelectedText
 */
export default function LogsTable({
  notFoundText,
  params,
  columns = [],
  refresh = false,
}: {
  notFoundText: string;
  params: { [key: string]: string | number };
  columns?: ColumnDef<Log>[];
  refresh?: boolean;
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const { logsCount, logs, isLoading } = useLogs(
    { page, limit: pageSize, orderby: getOrderByQuery(sort), ...params },
    params,
    refresh,
  );

  return (
    <div className="workflow">
      <TableUpgrade
        loading={isLoading}
        rows={logs}
        tableColumns={columns ? columns : DefaultColumns()}
        length={logsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        emptyText={notFoundText}
      />
    </div>
  );
}
