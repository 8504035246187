import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Select } from "formik-antd";
import { Integration } from "/app/src/models";
import { buildParams, simpleSchemaBuilder } from "/app/src/helpers";

import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ToolTip from "/app/src/components/generic/components/toolTip";
import useSetting from "/app/src/components/settings/setting";
import { mappingService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";

interface FormValues {
  value: number | undefined;
}

/**
 * Component to display the Group by setting for a single Data Pull Integration
 */
export default function SQLGroupBy({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const { settingValue, createUpdateSetting } = useSetting({
    integrationId: integration.id,
    type: "sqlGroupBy",
  });

  const { data: mappings } = useQuery({
    queryKey: ["mappings", { integrationId: integration.id }],
    queryFn: () =>
      mappingService.getAll(buildParams({ integrationId: integration.id })),
    select: (data) => data?.mappings,
    initialData: { mappings: [] },
  });

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await createUpdateSetting({
        ...values,
        type: "sqlGroupBy",
        integrationId: integration.id,
      }).then(() => {
        actions.resetForm();
      });
    },
    [createUpdateSetting, integration.id],
  );
  const labelContent = useMemo(
    () => (
      <>
        {t("translation:sql_group_by")}
        <ToolTip tip={t("translation:sql_group_by_tip")} />
      </>
    ),
    [t],
  );
  const form: (props: FormikProps<FormValues>) => JSX.Element = useCallback(
    ({ isSubmitting, dirty }) => (
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={20}>
            <Form.Item name="value" label={labelContent}>
              <Select name="value" size="large">
                {mappings?.map((mapping) => (
                  <Select.Option key={mapping.id} value={mapping.id}>
                    {mapping.key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [labelContent, mappings, t],
  );
  return (
    <Formik
      initialValues={{ value: Number(settingValue) } || { value: undefined }}
      validationSchema={simpleSchemaBuilder([
        { name: "value", type: "number", required: true },
      ])}
      onSubmit={onSubmit}
      component={form}
      enableReinitialize
    />
  );
}
