import { Row, Col } from "antd";
import EditWidget from "./edit";
import { DragOutlined } from "@ant-design/icons";
import Chart from "/app/src/components/charts";

import { Widget as WidgetType } from "/app/src/models";

/**
 * Component to render a dashboard widget. This widget is used for displaying
 * snapshot data in a chart.
 */
export default function Widget({
  widget,
  locked,
  isEditing,
}: {
  widget: WidgetType;
  locked: boolean;
  isEditing: boolean;
}) {
  return isEditing ? (
    <EditWidget widget={widget} />
  ) : !locked ? (
    <Row
      justify="center"
      align="middle"
      style={{ display: locked === true ? "none" : "", height: "300px" }}
    >
      <Col span={4}>
        <DragOutlined style={{ fontSize: "64px", color: "#82878e" }} />
      </Col>
    </Row>
  ) : (
    <>
      <h2>{widget.name}</h2>
      <Chart widget={widget} />
    </>
  );
}
