import { Col, Row } from "antd";
import NextDivider from "/app/src/components/NextUi/Divider";
import { Helmet } from "react-helmet";
import "./_updateLicense.scss";
import { WarningOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import homeIcon from "/app/src/images/home-alt.svg";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import UpdateLicenseForm from "./updateLicenseForm";

/**
 *
 * @returns Update License form header
 */
export default function UpdateLicenseFormHeader() {
  const { t } = useTranslation();
  const { activeWarehouses } = useStatusContext();

  return (
    <div>
      <Helmet>
        <title>{t("translation:update_license")} - ItemPath</title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 12, offset: 6 }}
        >
          <div className="box">
            <div className="licenseInfo">
              <div>
                <span className="warningIcon">
                  <WarningOutlined />
                </span>
                <span className="header">
                  {t("translation:update_your_license")}
                </span>
              </div>
              <span>
                <div className="homeIconContainer">
                  <img src={homeIcon} alt="Home" className="homeIcon" />
                </div>
                <span className="subHeader" style={{ paddingLeft: 10 }}>
                  {t("translation:active_warehouses")}:
                </span>
                <span className="smLicenseLimit" style={{ paddingLeft: 4 }}>
                  {activeWarehouses}
                </span>
              </span>
            </div>
            <span className="paragraph">
              {t("translation:warehouse_exceeded")}
            </span>
            <NextDivider className="my-6" />
            <UpdateLicenseForm />
          </div>
        </Col>
      </Row>
    </div>
  );
}
