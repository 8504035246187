import { userService } from "/app/src/services";
import { Action } from "./types";
import { getUserFromStorage } from "./helpers";

/**
 * Login the user using SSO and update local storage and auth context
 */
export function ssoLogin(dispatch: React.Dispatch<Action>, token) {
  dispatch({ type: "REQUEST_LOGIN" });
  return userService
    .ssoLogin()
    .then((response) => {
      if (response?.code === 401) {
        dispatch({ type: "LOGIN_ERROR", error: response.errors[0] });
        return response;
      } else {
        console.log("response in ssoLogin", response);
        //inject token as accessToken
        response.accessToken = token;
        localStorage.setItem("currentUser", JSON.stringify({ ...response }));
        dispatch({ type: "LOGIN_SUCCESS", payload: response });
        return response;
      }
    })
    .catch((error) => {
      dispatch({ type: "LOGIN_ERROR", error: error.errors[0] });
      return error;
    });
}

/**
 * Login the user and update the user context with the response from the API
 */
export function loginUser(
  dispatch: React.Dispatch<Action>,
  username: string,
  password: string,
  setSubmitting: (isSubmitting: boolean) => void,
) {
  dispatch({ type: "REQUEST_LOGIN" });
  return userService
    .login({ username, password })
    .then((response) => {
      setSubmitting(false);
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          id: response.id,
          username: response.username,
          refreshToken: response.refreshToken,
          roleId: response.roleId,
          timeZone: response.timeZone,
          viewId: response.viewId,
          accessToken: response.accessToken,
        }),
      );
      dispatch({ type: "LOGIN_SUCCESS", payload: response });
      return response;
    })
    .catch((error) => {
      setSubmitting(false);
      dispatch({ type: "LOGIN_ERROR", error: error.errors[0] });
      return error;
    });
}

/**
 * Update the user context with the response from the API
 */
export function updateUserContext(
  dispatch: React.Dispatch<Action>,
  response: any,
) {
  dispatch({ type: "UPDATE_USER", payload: response });
  const user = getUserFromStorage();
  localStorage.setItem(
    "currentUser",
    JSON.stringify({
      ...user,
      id: response.id,
      username: response.username,
      roleId: response.roleId,
      timeZone: response.timeZone,
      viewId: response.viewId,
    }),
  );
}

/**
 * Refresh the user and update the user context with the response from the API
 */
export function refreshUser(
  dispatch: React.Dispatch<Action>,
  refreshToken: string,
) {
  dispatch({ type: "REQUEST_REFRESH" });
  userService
    .refresh(refreshToken)
    .then((response) => {
      const user = getUserFromStorage();
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          ...user,
          accessToken: response.accessToken,
        }),
      );
      dispatch({ type: "REFRESH_SUCCESS", payload: response });
      return response;
    })
    .catch((error) => {
      return error.message;
    });
}

/**
 * Logout the user and clear the user context. Remove the user from local storage
 */
export function logout(dispatch: React.Dispatch<Action>) {
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("currentUser");
}
