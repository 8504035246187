import { useState, useCallback } from "react";
import { Row, Col } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import saveIcon from "/app/src/components/generic/title/saveIcon.svg";
import { Status as StatusT } from "/app/src/models";
import EditButton from "/app/src/components/generic/components/buttons/EditButton";
import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { statusService } from "/app/src/services";
import { DragOutlined, LockOutlined } from "@ant-design/icons";

interface FormValues {
  name: string | undefined;
}

/**
 * Component for a single draggable status in Order Builder
 */
export const DraggableStatus = ({
  status,
  updateStatus,
}: {
  status: StatusT;
  updateStatus: (updatedStatus: StatusT) => void;
}) => {
  const [editingName, setEditingName] = useState(false);

  const handleEditClick = useCallback((): void => {
    setEditingName(true);
  }, []);

  const queryClient = useQueryClient();
  const { mutateAsync: deleteStatus } = useMutation({
    mutationFn: (id: number) => {
      return statusService.deleteSingle(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["statuses"]);
    },
  });

  const onSubmitHandler = useCallback(
    async (values: FormValues, actions) => {
      if (values.name !== status.name) {
        await updateStatus({ id: status.id, ...values });
      }
      setEditingName(false);
      actions.setSubmitting(false);
    },
    [status.id, status.name, updateStatus],
  );
  const handleDeleteClick = useCallback(() => {
    deleteStatus(status.id);
  }, [status.id, deleteStatus]);
  const columnForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      () => (
        <Form>
          <Row>
            <Col span={20}>
              <Form.Item name="name" hasFeedback={false}>
                <Input type="text" name="name" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton className="saveButton" type="primary" block>
                <img src={saveIcon} alt="Save" />
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [],
    );

  return (
    <Row gutter={20} className="columnContainer">
      <Col span={1}>
        {status.type === "custom" ? (
          <DragOutlined style={{ fontSize: "16px", color: "#82878e" }} />
        ) : (
          <LockOutlined style={{ fontSize: "16px", color: "#82878e" }} />
        )}
      </Col>
      <Col span={8} className="name">
        {!editingName ? (
          <div style={{ paddingTop: "8px", fontSize: "12px" }}>
            {status.description}
          </div>
        ) : (
          <div style={{ paddingTop: "15px" }} />
        )}
        {editingName ? (
          <Formik
            component={columnForm}
            enableReinitialize
            initialValues={{
              name: status.name,
            }}
            validationSchema={simpleSchemaBuilder([
              { name: "name", type: "string", required: true },
            ])}
            onSubmit={onSubmitHandler}
          />
        ) : (
          <div style={{ display: "flex" }}>
            <h2>{status.name}</h2>
            <div style={{ marginLeft: "8px" }}>
              {status.type === "custom" && (
                <EditButton onClick={handleEditClick} />
              )}
            </div>
          </div>
        )}
      </Col>
      <Col span={1} offset={13} className="actions">
        <div className="titleActions">
          {status.type === "custom" && (
            <DeleteButton onClick={handleDeleteClick} />
          )}
        </div>
      </Col>
    </Row>
  );
};
