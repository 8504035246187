import React, { useCallback } from "react";
import { Row } from "antd";
import { User } from "/app/src/models";
import Status from "../users/status";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";
import NextModal from "/app/src/components/NextUi/Modal";
import ModalBuilder from "/app/src/components/NextUi/helpers/ModalBuilder";
import { useDisclosure } from "@nextui-org/react";

export default function UserMenu({
  user,
  toggleUserStatus,
}: {
  user: User;
  toggleUserStatus: () => void;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const toggleUser = useCallback(() => {
    toggleUserStatus();
    onOpenChange();
  }, [onOpenChange, toggleUserStatus]);

  return (
    <div className="userMenu sideMenu">
      <Row>
        <h4>
          {t("translation:user")} {t("translation:status")}:
        </h4>
      </Row>
      <Row>
        <h2>
          <p>{Status(user.status)}</p>
        </h2>
      </Row>
      <Row>
        {user.status !== 2 ? (
          <>
            <NextButton
              fullWidth
              color="default"
              variant="bordered"
              size="sm"
              onClick={onOpen}
              className="border-[#a12121] text-[#a12121] bg-white"
            >
              {t("translation:deactivate")} {t("translation:user")}
            </NextButton>
            <NextModal
              disableAnimation={false}
              isOpen={isOpen}
              onOpenChange={onOpenChange}
              placement="top"
            >
              {ModalBuilder({
                warning: true,
                modalHeader: t("translation:confirm_deactivate_user"),
                modalFooter: (
                  <>
                    <NextButton
                      size="sm"
                      color="default"
                      variant="bordered"
                      onClick={onOpenChange}
                      className="hover:text-blue-500 hover:border-blue-500"
                    >
                      {t("translation:cancel")}
                    </NextButton>
                    <NextButton size="sm" color="red" onClick={toggleUser}>
                      {t("translation:deactivate")}
                    </NextButton>
                  </>
                ),
              })}
            </NextModal>
          </>
        ) : (
          <>
            <NextButton
              fullWidth
              color="primary"
              variant="bordered"
              size="sm"
              onClick={onOpen}
            >
              {t("translation:activate")} {t("translation:user")}
            </NextButton>
            <NextModal
              disableAnimation={false}
              isOpen={isOpen}
              onOpenChange={onOpenChange}
              placement="top"
            >
              {ModalBuilder({
                modalHeader: t("translation:confirm_activate_user"),
                modalFooter: (
                  <>
                    <NextButton
                      size="sm"
                      color="default"
                      variant="bordered"
                      onClick={onOpenChange}
                      className="hover:text-blue-500 hover:border-blue-500"
                    >
                      {t("translation:cancel")}
                    </NextButton>
                    <NextButton size="sm" color="primary" onClick={toggleUser}>
                      {t("translation:activate")}
                    </NextButton>
                  </>
                ),
              })}
            </NextModal>
          </>
        )}
      </Row>
    </div>
  );
}
