import { PublicClientApplication } from "@azure/msal-browser";
import getAuthConfig from "./helpers/authConfig";
import { AuthProvider } from "./contexts/authentication";
import { MsalProvider } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(getAuthConfig());

/**
 * Authenticated wrappers for the application
 * Includes MSAL instance and authentication context
 */
export default function AuthWrappers({
  children,
}: {
  children: JSX.Element[];
}) {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>{children}</AuthProvider>
    </MsalProvider>
  );
}
