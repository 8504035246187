import { useCallback, useMemo } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import ToolTip from "/app/src/components/generic/components/toolTip";
import { newIntegrationSchema } from "/app/src/schemas";
import { useTranslation } from "react-i18next";
import { Integration, Report, Setting } from "/app/src/models";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  integrationService,
  settingService,
  reportService,
} from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  name: string | undefined;
  appId: number | undefined;
  report: string | undefined;
}

export default function Settings({
  integration,
  setting,
}: {
  integration: Integration;
  setting: Setting;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: reports } = useQuery({
    queryKey: ["reports", "Material"],
    queryFn: () => {
      return reportService.getAll(buildParams({ baseTable: "Material" }));
    },
    initialData: { reports: [] },
    select: (data: { reports: Report[] }) => {
      return data.reports;
    },
  });

  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (values: Integration) => {
      return integrationService
        .updateSingle(values.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["integration", Number(integration.id)],
        response,
      );
    },
  });

  const { mutateAsync: updateSetting } = useMutation({
    mutationFn: (values: Setting) => {
      return settingService
        .updateSingle(values.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settingsByIntegration", integration.id, 11],
        response,
      );
    },
  });

  const onSubmitUpdateHandler = useCallback(
    async (values: FormValues) => {
      //only update integration name if it's different
      if (integration.name !== values.name) {
        if (integration?.id) {
          await updateIntegration({ id: integration.id, name: values.name });
        }
      }
      //only update setting if report changed
      if (setting.name !== values.report) {
        if (setting?.id && values?.report) {
          const report: Report = JSON.parse(values.report);
          await updateSetting({
            ...setting,
            name: report.name,
            value: String(report.id),
          });
        }
      }
    },
    [integration, setting, updateIntegration, updateSetting],
  );

  const labelConent = useMemo(
    () => (
      <>
        {"Report"}
        <ToolTip tip={t("translation:cycle_count_report_tip")} />
      </>
    ),
    [t],
  );
  const newIntegrationForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={10}>
              <Form.Item name="name" label={t("translation:integration_name")}>
                <Input
                  suffix
                  name="name"
                  placeholder={t("translation:enter_name")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name="report" label={labelConent}>
                <Select name="report" size="large">
                  {reports.map((report) => (
                    <Select.Option
                      value={JSON.stringify(report)}
                      key={report.id}
                    >
                      {report.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <SubmitButton
                style={{ marginTop: "30px" }}
                type="primary"
                size="large"
                block
                disabled={!(dirty && isValid)}
              >
                {t("translation:update_integration")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, reports, labelConent],
    );
  return (
    <div className="box">
      <h1>{t("translation:integration_settings")}</h1>
      <Formik
        component={newIntegrationForm}
        enableReinitialize
        initialValues={{
          name: integration.name,
          appId: integration.appId,
          report: setting?.name,
        }}
        validationSchema={newIntegrationSchema}
        onSubmit={onSubmitUpdateHandler}
      />
    </div>
  );
}
