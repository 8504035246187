import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import Tip from "/app/src/components/NextUi/Tip";
import { Form, SubmitButton, Select, Input } from "formik-antd";
import { ColorResult, CompactPicker } from "react-color";
import { Formik } from "formik";

import { hueService, connectionService } from "/app/src/services";
import { WarningTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { WorkflowAction, App, Connection } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import NextButton from "/app/src/components/NextUi/Button";

type Zone = {
  id: number;
  name: string;
};
export default function Hue({
  app,
  action,
  addWorkflowAction,
  removeWorkflowAction,
  updateAction,
  isNew,
  resetSelectedApp,
}: {
  app: App;
  action: WorkflowAction;
  addWorkflowAction: (action: WorkflowAction) => Promise<any>;
  removeWorkflowAction: (action: WorkflowAction) => Promise<any> | undefined;
  updateAction: (action: WorkflowAction) => Promise<any>;
  isNew: boolean;
  resetSelectedApp: () => void;
}) {
  const [color, setColor] = useState<string | number>("#fcdc00");
  const [showing, setShowing] = useState(false);
  const [zones, setZones] = useState([]);
  const [connections, setConnections] = useState([]);
  const [connectionId, setConnectionId] = useState<number | string>();
  const { t } = useTranslation();

  //Hide the color picker
  function hidePicker() {
    setShowing(!showing);
  }

  useEffect(() => {
    connectionService
      .getAll(
        buildParams({
          type: "[or]Hue Remote;Hue Local",
        }),
      )
      .then((response) => {
        setConnections(response.connections);
      });
  }, []);

  const getZones = (id: string | number) => {
    hueService
      .getAllZones({ id: id })
      .then((response) => {
        if (response?.errors) {
          console.log(response.errors);
        } else {
          setZones(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isNew === false) {
      if (action?.to) {
        getZones(action.to);
      }
    }
  }, [action?.to, isNew]);

  useEffect(() => {
    if (isNew === false) {
      if (action?.message && action?.to) {
        setColor(action.message);
        setConnectionId(action.to);
      }
    }
  }, [isNew, action]);

  const handleChangeComplete = (
    colorT: ColorResult,
    setField: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    if (colorT) {
      setColor(colorT.hex);
      setField("message", colorT.hex);
    }
    setShowing(false);
  };

  const learnMore = () => {
    const isNewWindow = window.open(
      "https://itempath.com/docs/phillips-hue/setting-up-a-zone",
      "_blank",
      "noopener,noreferrer",
    );
    if (isNewWindow) isNewWindow.opener = null;
  };
  return (
    <Row justify="start" gutter={16}>
      <Col span={24}>
        <Formik
          enableReinitialize
          initialValues={{
            ...(isNew ? { to: "" } : { to: action.to }),
            ...(isNew ? { value: "" } : { value: action.value }),
            ...(isNew ? { message: "" } : { message: action.message }),
          }}
          onSubmit={(values, actions) => {
            if (isNew) {
              const newAction = { ...values, appId: app.id };
              addWorkflowAction(newAction).then((response) => {
                if (!response?.errors) {
                  actions.resetForm();
                  resetSelectedApp();
                }
              });
            } else {
              if (action?.id) {
                updateAction({ id: action.id, ...values })
                  .then((response) => {
                    if (!response?.errors) {
                      actions.resetForm();
                    }
                  })
                  .finally(() => {
                    actions.setSubmitting(false);
                  });
              }
            }
          }}
        >
          {({ dirty, setFieldValue }) => (
            <Form>
              <Row justify="start" gutter={16}>
                <Col span={3}>
                  <span className="blockText">{t("translation:activate")}</span>
                </Col>
                <Col span={9}>
                  <Form.Item name="to">
                    <Select
                      name="to"
                      size="large"
                      placeholder={t("translation:select_bridge")}
                      onChange={(value) => {
                        getZones(value);
                        //reset zone dropdown field
                        setFieldValue("value", "");
                      }}
                    >
                      {connections.map((c: Connection) => (
                        <Select.Option key={c.id} value={String(c.id)}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {connectionId === null && zones.length < 1 ? (
                    <Tip content={t("translation:no_hue_zones_found")}>
                      <WarningTwoTone
                        onClick={learnMore}
                        twoToneColor="#ffcc00"
                        style={{ fontSize: "32px" }}
                      />
                    </Tip>
                  ) : (
                    ""
                  )}
                  <Form.Item name="value">
                    <Select
                      name="value"
                      size="large"
                      placeholder={t("translation:select_zone")}
                    >
                      {zones.map((c: Zone) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name="message">
                    <Input.TextArea
                      name="message"
                      placeholder=""
                      hidden
                      value={color}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <div style={{ backgroundColor: `${color}` }}>
                    <NextButton
                      size="md"
                      variant="solid"
                      color="primary"
                      fullWidth
                      onClick={hidePicker}
                      className={`bg-[${color}]`}
                    >
                      {t("translation:select_colour")}
                    </NextButton>
                  </div>

                  {showing ? (
                    <CompactPicker
                      onChangeComplete={(colour) => {
                        handleChangeComplete(colour, setFieldValue);
                      }}
                    />
                  ) : null}
                </Col>

                <Col flex="auto">
                  <SubmitButton
                    type="primary"
                    size="large"
                    block
                    disabled={!dirty}
                  >
                    {isNew
                      ? t("translation:add_action")
                      : t("translation:save")}
                  </SubmitButton>
                </Col>
                {!isNew && (
                  <Col span={3}>
                    <NextButton
                      size="md"
                      variant="bordered"
                      color="default"
                      fullWidth
                      onClick={() => removeWorkflowAction(action)}
                      className="hover:border-blue-500 hover:text-blue-500 bg-white"
                    >
                      {t("translation:remove")}
                    </NextButton>
                  </Col>
                )}
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
