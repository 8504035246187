import React from "react";
import NextButton from "/app/src/components/NextUi/Button";
import { useTranslation } from "react-i18next";

export default function RefreshSetting({
  refresh,
  newSetting,
  setNewSetting,
}: {
  refresh: () => void;
  newSetting: boolean;
  setNewSetting: (newSetting: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <NextButton
      color="primary"
      onClick={() => {
        refresh();
        setNewSetting(!newSetting);
      }}
      size="sm"
    >
      {t("translation:refresh_list")}
    </NextButton>
  );
}
