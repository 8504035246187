import React, { useState, useContext, useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { getDateFormat } from "/app/src/helpers/time";
import { Form, Select } from "formik-antd";
import Verb from "./verb";
import Value from "./value";
import TimeZoneContext from "/app/src/contexts/TimeZoneContext";
import { useTranslation } from "react-i18next";
import { Report, ReportColumn, ReportFilter } from "/app/src/models";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { convertArrayToCSV } from "/app/src/helpers";
import FormikDisabler from "/app/src/components/generic/components/formikDisabler";
import DisabledSubmitButton from "/app/src/components/generic/components/buttons/DisabledSubmitButton";
import { useAccessContext } from "/app/src/contexts/hooks/useAccessContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reportFilterService } from "/app/src/services";
import { reportFilterSchema } from "/app/src/schemas/reports/reportSchema";
import { handlePromiseError } from "/app/src/helpers/api";

function formatForm(values: FormValues, timeZone: string) {
  let formattedValue = values.value;
  // If value is a list, convert to comma separated string
  formattedValue = convertArrayToCSV(formattedValue);
  if (values.dateRange === "specificTime") {
    formattedValue = getDateFormat(
      formattedValue,
      "YYYY-MM-DDTHH:mm:ss",
      timeZone,
    );
  }
  return {
    reportColumnId: values.reportColumnId,
    value: formattedValue,
    verb: values.verb,
    dateRange: values.dateRange,
    reportId: values.reportId,
  };
}

interface FormValues {
  reportColumnId?: number;
  verb?: string;
  reportId?: number;
  value: string | string[];
  dateRange: string;
}
export default function NewReportFilter({
  report,
  reportColumns,
}: {
  report: Report;
  reportColumns: ReportColumn[];
}) {
  const { t } = useTranslation();
  const [filterType, setFilterType] = useState("");
  const [filterVerb, setFilterVerb] = useState("");
  const { timeZone } = useContext(TimeZoneContext);
  const { canEdit } = useAccessContext();
  const queryClient = useQueryClient();

  const { mutateAsync: addFilter } = useMutation({
    mutationFn: (values: FormValues) => {
      return reportFilterService
        .createSingle(formatForm(values, timeZone))
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["reportFilters", report.id],
        (oldData: { report_filters: ReportFilter[] }) => {
          return {
            report_filters: [...oldData.report_filters, data.report_filter],
          };
        },
      );
    },
  });

  const addFilterHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      values.reportId = report.id;
      await addFilter(formatForm(values, timeZone)).finally(() => {
        actions.resetForm();
      });
    },
    [addFilter, report.id, timeZone],
  );

  const mapColumns = (c: ReportColumn) => {
    return (
      <Select.Option key={c.id} value={c.id}>
        {c.name}
      </Select.Option>
    );
  };
  // Set filter type based on column selected
  const handleOnChange = useCallback(
    (value: number) => {
      const col = reportColumns.find((obj) => {
        return obj.id === value;
      });
      if (col?.filterType) {
        if (col.filterType === "DateTime" && col.type === "comparison") {
          setFilterType("Number");
        } else {
          setFilterType(col.filterType);
        }
      }
    },
    [reportColumns],
  );

  const newFilterForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid, isSubmitting }) => (
        <Form>
          <FormikDisabler disabled={!canEdit} />
          <Row justify="start" gutter={16}>
            <Col span={5}>
              <Form.Item name="reportColumnId">
                <SearchFilter
                  name="reportColumnId"
                  placeholder={t("translation:select_column")}
                  mapOptionsFn={mapColumns}
                  list={reportColumns}
                  sort
                  onChange={handleOnChange}
                />
              </Form.Item>
            </Col>
            <Verb type={filterType} setFilterVerb={setFilterVerb} />

            <Value type={filterType} verb={filterVerb} />
            <Col span={3} offset={1}>
              <DisabledSubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting || !isValid}
              >
                {t("translation:add")}
              </DisabledSubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [canEdit, filterType, filterVerb, handleOnChange, reportColumns, t],
    );
  return (
    <div className="newFilter">
      <Formik
        component={newFilterForm}
        initialValues={{
          verb: "",
          value: "",
          dateRange: "",
        }}
        validationSchema={reportFilterSchema}
        onSubmit={addFilterHandler}
      />
    </div>
  );
}
