import React from "react";
import { Col } from "antd";
import { Form, Select } from "formik-antd";
import { useTranslation } from "react-i18next";

function DateVerb({
  setFilterVerb,
}: {
  setFilterVerb: (value: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <Form.Item name="verb">
      <Select
        name="verb"
        size="large"
        onChange={(value) => {
          setFilterVerb(value);
        }}
      >
        <Select.Option value="during">{t("translation:during")}</Select.Option>
        <Select.Option value="before">{t("translation:before")}</Select.Option>
        <Select.Option value="after">{t("translation:after")}</Select.Option>
      </Select>
    </Form.Item>
  );
}

function StringVerb() {
  const { t } = useTranslation();

  return (
    <Form.Item name="verb">
      <Select name="verb" size="large">
        <Select.Option value="equals">{t("translation:is")}</Select.Option>
        <Select.Option value="not">{t("translation:not")}</Select.Option>
        <Select.Option value="sw">{t("translation:starts_with")}</Select.Option>
        <Select.Option value="ew">{t("translation:ends_with")}</Select.Option>
        <Select.Option value="inc">{t("translation:includes")}</Select.Option>
      </Select>
    </Form.Item>
  );
}

function NumberVerb() {
  const { t } = useTranslation();

  return (
    <Form.Item name="verb">
      <Select name="verb" size="large">
        <Select.Option value="equals">{t("translation:is")}</Select.Option>
        <Select.Option value="not">{t("translation:not")}</Select.Option>
        <Select.Option value="gt">
          {t("translation:greater_than")}
        </Select.Option>
        <Select.Option value="lt">{t("translation:less_than")}</Select.Option>
      </Select>
    </Form.Item>
  );
}

function DefaultVerb() {
  const { t } = useTranslation();
  return (
    <Form.Item name="verb">
      <Select name="verb" size="large">
        <Select.Option value="equals">{t("translation:is")}</Select.Option>
        <Select.Option value="not">{t("translation:not")}</Select.Option>
        <Select.Option value="sw">{t("translation:starts_with")}</Select.Option>
        <Select.Option value="ew">{t("translation:ends_with")}</Select.Option>
        <Select.Option value="inc">{t("translation:includes")}</Select.Option>
        <Select.Option value="gt">
          {t("translation:greater_than")}
        </Select.Option>
        <Select.Option value="lt">{t("translation:less_than")}</Select.Option>
      </Select>
    </Form.Item>
  );
}

export default function Verb({
  type,
  setFilterVerb,
}: {
  type: string;
  setFilterVerb: (value: string) => void;
}) {
  return (
    <Col span={4}>
      {type === "DateTime" && <DateVerb setFilterVerb={setFilterVerb} />}
      {type === "Number" && <NumberVerb />}
      {type === "String" && <StringVerb />}
      {type === "UUID" && <StringVerb />}
      {type === "None" && <DefaultVerb />}
      {type === "" && <DefaultVerb />}
    </Col>
  );
}
