import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { shelfService } from "/app/src/services";
import Search from "../search";
import getOrderByQuery from "/app/src/helpers/table";
import { useTranslation } from "react-i18next";
import { Shelf as ShelfType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

export default function Shelves() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<ShelfType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("number", {
        id: "number",
        cell: (info) => info.getValue(),
        header: () => t("translation:number"),
      }),
      columnHelper.accessor("sequenceNumber", {
        id: "sequenceNumber",
        cell: (info) => info.getValue(),
        header: () => t("translation:sequence_number"),
      }),
    ],
    [columnHelper, t],
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const goToShelf = (shelfId: number) => {
    navigate(`/explorer/shelves/${shelfId}`);
  };

  //Gets all shelves
  const { data: shelves, isFetching } = useQuery({
    queryKey: ["shelves", page, pageSize, sort, searchString],
    queryFn: () => {
      return shelfService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { shelves: [] },
    select: (data: { shelves: ShelfType[] }) => {
      return data.shelves;
    },
  });

  //Gets count of all shelves
  const { data: shelvesCount } = useQuery({
    queryKey: ["shelvesCount", searchString],
    queryFn: () => {
      return shelfService.getCount(buildParams({ search: searchString }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:shelves")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <TableUpgrade
        loading={isFetching}
        rows={shelves}
        tableColumns={columns}
        length={shelvesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        enableRowSelection
        rowClicked={goToShelf}
        emptyText={t("translation:no_shelves_found")}
      />
    </div>
  );
}
