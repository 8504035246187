import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { TwitterPicker, ColorResult } from "react-color";
import { widgetDatasetService } from "/app/src/services";
import { WidgetDataset } from "/app/src/models";
import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";
import { handlePromiseError } from "/app/src/helpers/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function Dataset({ dataset }: { dataset: WidgetDataset }) {
  const [colourOpen, setColourOpen] = useState(false);

  const toggleColour = () => {
    setColourOpen((prev) => !prev);
  };
  const queryClient = useQueryClient();

  const { mutateAsync: deleteDataset } = useMutation({
    mutationFn: (dataset: WidgetDataset) => {
      return widgetDatasetService
        .deleteSingle(dataset.id)
        .then(handlePromiseError);
    },
    onSuccess: () => {
      queryClient.setQueryData(
        ["widgetDatasets", dataset.widgetId],
        (oldData: { widget_datasets: WidgetDataset[] }) => {
          queryClient.invalidateQueries(["widgetData", dataset.widgetId]);
          return {
            widget_datasets: oldData.widget_datasets.filter(
              (d) => d.id !== dataset.id,
            ),
          };
        },
      );
    },
  });
  const { mutateAsync: updateDataset } = useMutation({
    mutationFn: (updatedDataset: WidgetDataset) => {
      return widgetDatasetService
        .updateSingle(updatedDataset.id, updatedDataset)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["widgetDatasets", dataset.widgetId],
        (oldData: { widget_datasets: WidgetDataset[] }) => {
          queryClient.invalidateQueries(["widgetData", dataset.widgetId]);
          return {
            widget_datasets: oldData.widget_datasets.map((dataset) => {
              if (dataset.id === response.widget_dataset.id) {
                return response.widget_dataset;
              }
              return dataset;
            }),
          };
        },
      );
    },
  });

  const updateColour = useCallback(
    (colour: ColorResult) => {
      updateDataset({ id: dataset.id, colour: colour.hex });
      setColourOpen(false);
    },
    [dataset.id, updateDataset],
  );

  const handleDeleteClick = useCallback((): void => {
    deleteDataset(dataset);
  }, [dataset, deleteDataset]);

  return (
    <div className="dataset">
      <Link
        className="link"
        to={`/reports/${dataset.Snapshot.reportId}/snapshots`}
      >
        {dataset.Snapshot.name}
        <span style={{ fontStyle: "italic" }}>
          ({dataset.Snapshot.reportName})
        </span>
      </Link>
      <div className="colorPicker">
        <button
          className="colorButton"
          onClick={() => toggleColour()}
          style={{ background: dataset.colour }}
        />

        {colourOpen && (
          <div className="popover">
            <TwitterPicker
              triangle="top-right"
              color={dataset.colour}
              onChangeComplete={updateColour}
            />
          </div>
        )}
      </div>
      <DeleteButton onClick={handleDeleteClick} />
    </div>
  );
}
