import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { roleService } from "/app/src/services";
import { Row, Col, message } from "antd";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Form, SubmitButton, Select } from "formik-antd";
import { userProfileSchema } from "/app/src/schemas";
import {
  useAuthState,
  updateUserContext,
  useAuthDispatch,
} from "/app/src/contexts/authentication";
import { getTimeZones } from "/app/src/helpers/time";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { User, Role } from "/app/src/models";
import { mapTimeZones } from "/app/src/components/generic/formatting/timeZone";
import NextModal from "/app/src/components/NextUi/Modal";
import NextButton from "/app/src/components/NextUi/Button";
import ModalBuilder from "/app/src/components/NextUi/helpers/ModalBuilder";

type FormValues = {
  roleId: number | undefined;
  language: string | undefined;
  type: string | undefined;
  timeZone: string | undefined;
};

/**
 * Component for updating a user's profile. Some fields are not included here because they are
 * not used in SSO
 */
export default function SsoProfile({
  profileUser,
  updateUser,
}: {
  profileUser: User;
  updateUser: (user: User) => Promise<any>;
}) {
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: () => {
      return roleService.getAll();
    },
    initialData: { roles: [] },
    select: (data: { roles: Role[] }) => {
      return data.roles;
    },
  });

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const updateProfileForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ isValid, dirty }) => {
        const languages = [
          { code: "en", name: "English" },
          { code: "fr", name: "Français" },
        ];

        return (
          <Form layout="vertical">
            <Row justify="start" gutter={20}>
              <Col span={12}>
                <Form.Item name="roleId" label={t("translation:role")}>
                  <Select
                    name="roleId"
                    size="large"
                    placeholder={t("translation:select_role")}
                  >
                    {roles.map((c: Role) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="language" label={t("translation:language")}>
                  <Select
                    name="language"
                    size="large"
                    placeholder={t("translation:select_language")}
                  >
                    {languages.map((c) => (
                      <Select.Option key={c.code} value={c.code}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item name="timeZone" label={t("translation:Time_Zone")}>
                  <SearchFilter
                    list={getTimeZones()}
                    name="timeZone"
                    placeholder={t("translation:select_time_zone")}
                    sort
                    mapOptionsFn={mapTimeZones}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <SubmitButton
                  type="primary"
                  size="large"
                  disabled={!(dirty && isValid)}
                >
                  {t("translation:save")}
                </SubmitButton>
                <NextModal
                  disableAnimation={false}
                  isOpen={isModalOpen}
                  onOpenChange={setIsModalOpen}
                  placement="top"
                >
                  {ModalBuilder({
                    warning: true,
                    modalHeader: t("translation:activate_user_warning"),
                    modalFooter: (
                      <NextButton
                        fullWidth
                        onClick={closeModal}
                        color="primary"
                      >
                        {t("translation:ok")}
                      </NextButton>
                    ),
                  })}
                </NextModal>
              </Col>
            </Row>
          </Form>
        );
      },
      [closeModal, isModalOpen, roles, t],
    );

  /**
   * Handle the form submission
   */
  const onSubmitHandler = useCallback(
    (values: FormValues, actions: FormikHelpers<FormValues>) => {
      if (profileUser.status === 2) {
        setIsModalOpen(true);
        actions.setSubmitting(false);
      } else {
        if (!profileUser?.id) {
          return;
        }
        updateUser(values)
          .then((response) => {
            //update language and timeZone settings if user profile matches logged in user
            if (response?.user.id === user.id) {
              //only update language if different
              if (values.language !== profileUser.language && values.language) {
                i18n.changeLanguage(values.language);
              }
              updateUserContext(dispatch, response.user);
            }
            message.success(t("translation:ok"));
            actions.resetForm();
            actions.setSubmitting(false);
            return response;
          })
          .catch((error) => {
            handleSubmissionErrors(error.errors, actions.setFieldError);
            actions.setSubmitting(false);
          });
      }
    },
    [
      dispatch,
      i18n,
      profileUser?.id,
      profileUser.language,
      profileUser.status,
      t,
      updateUser,
      user.id,
    ],
  );
  return (
    <div className="box">
      <Formik
        enableReinitialize
        component={updateProfileForm}
        initialValues={{
          roleId: profileUser.roleId,
          language: profileUser.language,
          type: profileUser.type,
          timeZone: profileUser.timeZone,
        }}
        validationSchema={userProfileSchema}
        onSubmit={onSubmitHandler}
      />
    </div>
  );
}
