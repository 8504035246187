import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Log } from "../models";
import { Error } from "../types";

/**
 * Get all logs
 * @param params URLSearchParams
 * @returns Promise<{ logs: Log[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ logs: Log[] }> {
  return genericGetAll("logs", params);
}

/**
 * Get a single log
 * @param id number | string
 * @returns Promise<{ log: Log }>
 */
function getSingle(id: number | string): Promise<{ log: Log }> {
  return genericGetSingle("logs", id);
}

/**
 * Update a single log
 * @param id number | string
 * @param log { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ log: Log } | Error>
 */
function updateSingle(
  id: number | string,
  log: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ log: Log } | Error> {
  return genericUpdateSingle("logs", id, log);
}

/**
 * Create a single log
 * @param log { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ log: Log } | Error>
 */
function createSingle(log: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ log: Log } | Error> {
  return genericCreateSingle("logs", log);
}

/**
 * Delete a single log
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("logs", id);
}

/**
 * Gets the count of logs
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("logs", params);
}

export const logService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
