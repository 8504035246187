import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import NextButton from "/app/src/components/NextUi/Button";

interface FormValues {
  value: string | undefined;
}

/**
 *
 * @returns DynamicButtons component
 */
export default function DynamicButtons({
  disabled = false,
}: {
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const { setFieldValue, values } = useFormikContext<FormValues>();

  /**
   * Function to add lastDay to the value
   */
  const onClickLastDay = useCallback(() => {
    const currentValue = values.value ?? "";
    setFieldValue("value", `${currentValue}{lastDay}`);
  }, [setFieldValue, values.value]);

  /**
   * Function to add lastWeek to the value
   */
  const onClickLastWeek = useCallback(() => {
    const currentValue = values.value ?? "";
    setFieldValue("value", `${currentValue}{lastWeek}`);
  }, [setFieldValue, values.value]);

  return (
    <div className="mt-2">
      <NextButton
        onClick={onClickLastDay}
        isDisabled={disabled}
        color="default"
        size="sm"
        className="mr-2 hover:text-blue-500 hover:border-blue-500"
        variant="bordered"
      >
        {t("translation:lastDay")}
      </NextButton>
      <NextButton
        onClick={onClickLastWeek}
        isDisabled={disabled}
        color="default"
        size="sm"
        className="hover:text-blue-500 hover:border-blue-500"
        variant="bordered"
      >
        {t("translation:lastWeek")}
      </NextButton>
    </div>
  );
}
