import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row } from "antd";

import { integrationService } from "/app/src/services";
import Integration from "./integration";
import Controls from "./_controls";
import { Integration as IntegrationType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";

export default function IntegrationList({ appId }: { appId: number }) {
  const [integrations, setIntegrations] = useState<IntegrationType[]>([]);

  useEffect(() => {
    integrationService
      .getAll(buildParams({ appId: appId }))
      .then((response) => setIntegrations(response.integrations))
      .catch((error) => {
        console.log(error);
      });
  }, [appId]);

  return (
    <div className="apps">
      <Helmet>
        <title>Order Processors - ItemPath</title>
      </Helmet>
      <Controls />
      <Row gutter={[24, 24]}>
        {integrations.map((integration) => (
          <Integration
            appId={appId}
            key={integration.id}
            integration={integration}
          />
        ))}
      </Row>
    </div>
  );
}
