import React, { Dispatch, SetStateAction } from "react";
import { Card, CardBody, CardFooter } from "@nextui-org/react";
import IconBuilder from "/app/src/components/icons/IconBuilder";
import { ExclamationCircle } from "/app/src/components/icons/icons";

interface NotificationCardProps {
  headerText: string;
  bodyText: string;
  onClick: () => void;
  openNotification: boolean;
  setOpenNotification?: Dispatch<SetStateAction<boolean>>;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  headerText,
  bodyText,
  onClick,
  openNotification,
  setOpenNotification,
}) => {
  const handleDismiss = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    console.log("dismissed");
    if (setOpenNotification) {
      setOpenNotification(false);
    }
  };

  if (!openNotification) return null;

  return (
    <div className="fixed top-4 right-4 z-50 ">
      <Card
        isPressable
        isHoverable
        radius="md"
        className="p-2"
        onPress={onClick}
      >
        <CardBody className="grid grid-cols-[auto,1fr] gap-4 w-[350px]">
          <IconBuilder icon={ExclamationCircle} color="#ff9900" size={30} />
          <div className="text-base text-gray-800">{headerText}</div>
        </CardBody>
        <CardFooter className="pl-14 text-sm text-gray-600 mr-2">
          {bodyText}
        </CardFooter>
      </Card>
      <div
        className="fixed cursor-pointer pr-2 pt-2 top-4 right-4 z-[100]"
        onClick={handleDismiss}
      >
        ✕
      </div>
    </div>
  );
};

export default NotificationCard;
