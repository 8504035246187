import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Role } from "/app/src/models";

export default function RoleMenu({ role }: { role: Role }) {
  const { t } = useTranslation();
  return (
    <div className="userMenu sideMenu">
      <div className="box">
        <ul>
          <li>
            <NavLink to={`/users/roles/${role.id}`}>
              <>
                {t("translation:role")} {t("translation:details")}
              </>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
