import Spin from "/app/src/components/NextUi/Spin";

import SnapshotChart from "./snapshotChart";
import ReportTable from "./reportTable";
import { Report, ReportColumn } from "/app/src/models";

/**
 * Component to display the snapshot chart and report table
 * @param param0 props contain report and report columns
 */
export default function Data({
  report,
  columns,
}: {
  report: Report;
  columns: ReportColumn[];
}) {
  return (
    <div className="reportData">
      {report.id ? (
        <>
          <SnapshotChart report={report} />
          <ReportTable columns={columns} report={report} />
        </>
      ) : (
        <div className="dataTable flex justify-center p-10 h-[400px]">
          <Spin size="lg" />
        </div>
      )}
    </div>
  );
}
