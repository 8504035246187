import { Col } from "antd";
import { Form, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import PasswordField from "/app/src/components/generic/components/passwordField";

/**
 * Component to manage displaying the form fields related to the connection type
 */
export default function ConnectionType({
  includeAuth,
  includeDatabase,
  hidePassword,
  hideToken,
}: {
  includeAuth: boolean;
  includeDatabase: boolean;
  hidePassword: boolean;
  hideToken: boolean;
}) {
  const { t } = useTranslation();

  if (includeAuth) {
    return (
      <>
        <Col span={12}>
          <Form.Item name="username" label={t("translation:username")}>
            <Input suffix name="username" className="user" size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="password" label={t("translation:password")}>
            <PasswordField name="password" passwordExists={hidePassword} />
          </Form.Item>
        </Col>
        {includeDatabase && (
          <Col span={12}>
            <Form.Item name="token" label={t("translation:database")}>
              <PasswordField name="token" passwordExists={hideToken} />
            </Form.Item>
          </Col>
        )}
      </>
    );
  }
}
