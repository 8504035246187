import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { shelfService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Shelf as ShelfType } from "/app/src/models";
export default function Shelf() {
  const { t } = useTranslation();
  const { shelfId } = useParams();

  //Get the Single Shelf from URL
  const { data: shelf } = useQuery({
    queryKey: ["shelf", shelfId],
    queryFn: () => {
      return shelfService.getSingle(shelfId);
    },
    initialData: { shelf: {} },
    select: (data: { shelf: ShelfType }) => {
      return data.shelf;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{shelf?.name}</title>
      </Helmet>
      {shelf && (
        <div>
          <div className="box">
            <h1>{shelf?.name}</h1>
            <div className="bigItems">
              <div className="bigItem">
                <h4>{t("translation:number")}</h4>
                <span>{shelf?.number}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
