import React, { useCallback } from "react";
import { Col } from "antd";
import { Form, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { Day, Month, Week } from "./frequencies";

interface FormValues {
  triggerFrequency: string | undefined;
  triggerTime: string;
  triggerInterval: string | undefined;
  triggerNumber: string[] | string;
  workflowId: number | undefined;
}

export default function Time() {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<FormValues>();

  /**
   * Reset triggerTime and triggerNumber when triggerFrequency changes
   */
  const handleFrequencyChange = useCallback(() => {
    setFieldValue("triggerTime", undefined);
    setFieldValue("triggerNumber", undefined);
    setFieldTouched("triggerTime", false);
    setFieldTouched("triggerNumber", false);
  }, [setFieldValue, setFieldTouched]);

  const { t } = useTranslation();
  return (
    <>
      <Col span={3}>
        <Form.Item name="triggerFrequency">
          <Select
            name="triggerFrequency"
            size="large"
            onChange={handleFrequencyChange}
          >
            <Select.Option value="minute">
              {t("translation:minute")}
            </Select.Option>
            <Select.Option value="hour">{t("translation:hour")}</Select.Option>
            <Select.Option value="day">{t("translation:day")}</Select.Option>
            <Select.Option value="week">{t("translation:week")}</Select.Option>
            <Select.Option value="month">
              {t("translation:month")}
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
      {values.triggerFrequency === "minute" && <Col span={13} />}
      {values.triggerFrequency === "hour" && <Col span={13} />}
      {values.triggerFrequency === "day" && <Day />}
      {values.triggerFrequency === "week" && <Week />}
      {values.triggerFrequency === "month" && <Month />}
      {!values.triggerFrequency && <Col span={13} />}
    </>
  );
}
