import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { Integration } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useSetting from "/app/src/components/settings/setting";

interface FormValues {
  value: string | undefined;
}

/**
 * Component to display the DefaultMapping setting for a single Data Pull Integration
 */
export default function DefaultMapping({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const { settingValue, createUpdateSetting } = useSetting({
    integrationId: integration.id,
    type: "defaultMapping",
  });

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await createUpdateSetting({
        ...values,
        type: "defaultMapping",
        integrationId: integration.id,
      }).then(() => {
        actions.resetForm();
      });
    },
    [createUpdateSetting, integration.id],
  );

  const form: (props: FormikProps<FormValues>) => JSX.Element = useCallback(
    ({ isSubmitting, dirty }) => (
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="value"
              label={<>{t("translation:default_mapping")}</>}
            >
              <Input name="value" size="large" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );
  return (
    <Formik
      initialValues={{ value: settingValue }}
      validationSchema={simpleSchemaBuilder([
        { name: "value", type: "string", required: true },
      ])}
      onSubmit={onSubmit}
      component={form}
      enableReinitialize
    />
  );
}
