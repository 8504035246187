import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PowerPickTimeZoneToLocal } from "/app/src/components/generic/formatting/dateTime";
import { costCenterService } from "/app/src/services";

import Search from "../search";

import { useTranslation } from "react-i18next";
import { CostCenter as CostCenterType } from "/app/src/models";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

export default function CostCenters() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<CostCenterType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => info.getValue(),
        header: () => t("translation:description"),
      }),
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => {
          return (
            <PowerPickTimeZoneToLocal
              date={info.getValue()}
              format={"YYYY-MM-DD HH:mm:ss"}
            />
          );
        },
        header: () => t("translation:creation_date"),
      }),
    ],
    [columnHelper, t],
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const goToCostCenter = (costCenterId: number) => {
    navigate(`/explorer/cost_centers/${costCenterId}`);
  };

  // Get cost center for this page
  const { data: costCenters, isFetching } = useQuery({
    queryKey: ["costCenters", searchString, page, pageSize, sort],
    queryFn: () => {
      return costCenterService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { cost_centers: [] },
    select: (data: { cost_centers: CostCenterType[] }) => {
      return data.cost_centers;
    },
  });

  //Get the count of cost centers
  const { data: costCentersCount } = useQuery({
    queryKey: ["costCentersCount", searchString],
    queryFn: () => {
      return costCenterService.getCount(
        buildParams({
          search: searchString,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:cost_centers")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <TableUpgrade
        loading={isFetching}
        rows={costCenters}
        tableColumns={columns}
        length={costCentersCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        enableRowSelection
        rowClicked={goToCostCenter}
        emptyText={`${t("translation:no")} ${t("translation:cost_centers")} ${t(
          "translation:found",
        )}`}
      />
    </div>
  );
}
