import { Helmet } from "react-helmet";
import { storageUnitService } from "/app/src/services";
import { useParams } from "react-router-dom";
import VLM from "./vlm";
import { useQuery } from "@tanstack/react-query";
import { StorageUnit as StorageUnitType } from "/app/src/models";
export default function StorageUnit() {
  const { storageUnitId } = useParams();

  //Get data
  const { data: storageUnit } = useQuery({
    queryKey: ["storageUnit", storageUnitId],
    queryFn: () => {
      return storageUnitService.getSingle(storageUnitId);
    },
    initialData: { storage_unit: {} },
    select: (data: { storage_unit: StorageUnitType }) => {
      return data.storage_unit;
    },
  });

  return (
    <div className="storageUnit">
      <Helmet>
        <title>{storageUnit?.name}</title>
      </Helmet>

      {storageUnit?.type === 5 && <VLM storageUnit={storageUnit} />}
    </div>
  );
}
