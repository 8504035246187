import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Spin from "/app/src/components/NextUi/Spin";

export default function StatusIcon({ status }: { status: number }) {
  if (status === 1) {
    return <CheckOutlined className="success" />;
  } else if (status === 2) {
    return <Spin size="sm" />;
  } else {
    return <CloseOutlined className="fail" />;
  }
}
