import { Connection } from "/app/src/models/connection";
import ConnectionType from "./connectionType";

/**
 * Wrapper for Connection Type component. Uses the connection instance to determine
 * which fields to display.
 */
export default function ConnectionTypeWrapper({
  connection,
}: {
  connection: Connection;
}) {
  return (
    <ConnectionType
      includeAuth={["Password", "Basic", "OAuth2"].includes(
        connection.authentication,
      )}
      includeDatabase={["MSSQL", "IBM2"].includes(connection.type)}
      hidePassword={connection.passwordExists}
      hideToken={connection.tokenExists}
    />
  );
}
