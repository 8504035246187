import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { TriggerType } from "../models";
import { Error } from "../types";

/**
 * Gets all trigger types
 * @param params URLSearchParams
 * @returns Promise<{ trigger_types: TriggerType[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ trigger_types: TriggerType[] }> {
  return genericGetAll("trigger_types", params);
}

/**
 * Gets a single trigger type
 * @param id number | string
 * @returns Promise<{ trigger_type: TriggerType }>
 */
function getSingle(
  id: number | string,
): Promise<{ trigger_type: TriggerType }> {
  return genericGetSingle("trigger_types", id);
}

/**
 * Update a single trigger type
 * @param id number | string
 * @param integration { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ trigger_type: TriggerType } | Error>
 */
function updateSingle(
  id: number | string,
  integration: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ trigger_type: TriggerType } | Error> {
  return genericUpdateSingle("trigger_types", id, integration);
}

/**
 * Create a new trigger type
 * @param integration { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ trigger_type: TriggerType } | Error>
 */
function createSingle(integration: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ trigger_type: TriggerType } | Error> {
  return genericCreateSingle("trigger_types", integration);
}

/**
 * Delete a single trigger type
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("trigger_types", id);
}

/**
 * Gets the count of trigger types
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("trigger_types", params);
}

export const triggerTypeService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
