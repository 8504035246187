import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { parameterValueService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { ParameterValue as ParameterValueType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers";
import Search from "../search";
import getOrderByQuery from "/app/src/helpers/table";
import { createColumnHelper } from "@tanstack/react-table";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

/**
 * Component for displaying all parameter values in a table
 */
export default function ParameterValues() {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const columnHelper = createColumnHelper<ParameterValueType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
      columnHelper.accessor("stationName", {
        id: "stationName",
        cell: (info) => info.getValue(),
        header: t("translation:station"),
      }),
      columnHelper.accessor("value", {
        id: "value",
        cell: (info) => info.getValue(),
        header: t("translation:value"),
      }),
    ],
    [columnHelper, t],
  );

  //Gets all parameterValues
  const { data: parameterValues, isFetching } = useQuery({
    queryKey: ["parameterValues", page, pageSize, searchString, sort],
    queryFn: () =>
      parameterValueService.getAll(
        buildParams({
          page,
          search: searchString,
          orderby: getOrderByQuery(sort),
          limit: pageSize,
        }),
      ),
    initialData: { parameter_values: [] },
    select: (data: { parameter_values: ParameterValueType[] }) => {
      return data.parameter_values;
    },
  });

  //Gets count of all paramenterValues
  const { data: parameterValuesCount } = useQuery({
    queryKey: ["parameterValuesCount", searchString],
    queryFn: () => {
      return parameterValueService.getCount(
        buildParams({ search: searchString }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:parameter_values")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <TableUpgrade
        loading={isFetching}
        rows={parameterValues}
        tableColumns={columns}
        length={parameterValuesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        emptyText={t("translation:no_parameter_values_found")}
      />
    </div>
  );
}
