import React, { useCallback, useMemo } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, InputNumber } from "formik-antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ToolTip from "/app/src/components/generic/components/toolTip";
import getInitialValues from "./formHelper";
import { handlePromiseError } from "/app/src/helpers/api";
interface FormValues {
  value: string | undefined;
}
/**
 * Form for creating and updating the max records setting
 * @param integration Integration
 */
export default function MaxRecords({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const labelContent = useMemo(
    () => (
      <>
        {t("translation:max_records")}
        <ToolTip tip={t("translation:max_records_tip")} />
      </>
    ),
    [t],
  );
  const maxRecordsSettingQuery = useQuery({
    queryKey: ["setting", "maxRecords", integration.id],
    queryFn: () =>
      settingService.getAll(
        buildParams({ integrationId: integration.id, type: "maxRecords" }),
      ),
  });

  const { mutateAsync: maxRecords } = useMutation({
    mutationFn: (values: FormValues) => {
      if (maxRecordsSettingQuery.data?.settings.length < 1) {
        return settingService
          .createSingle({
            name: "maxRecords",
            value: values.value,
            type: "maxRecords",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      } else {
        return settingService
          .updateSingle(maxRecordsSettingQuery.data?.settings[0]?.id, {
            value: values.value,
          })
          .then(handlePromiseError);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["setting", "maxRecords", integration.id], {
        settings: [data.setting],
      });
    },
  });

  const maxRecordsHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await maxRecords(values).then(() => {
        actions.resetForm();
      });
    },
    [maxRecords],
  );

  /**
   * Form for the max records setting
   */
  const maxRecordsForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item name="value" label={labelContent} hasFeedback={false}>
                <InputNumber
                  name="value"
                  min={1}
                  max={100}
                  size="large"
                  style={{ width: "100%" }}
                  placeholder={t("translation:max_records_placeholder")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [labelContent, t],
    );
  return (
    <Formik
      initialValues={{
        value: getInitialValues(
          maxRecordsSettingQuery.data?.settings[0]?.value,
        ),
      }}
      enableReinitialize
      onSubmit={maxRecordsHandler}
      component={maxRecordsForm}
    />
  );
}
