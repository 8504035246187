import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "antd";
import { useAuthState } from "/app/src/contexts/authentication";
import { useTranslation } from "react-i18next";
import { widgetService } from "/app/src/services";
import WidgetArea from "./WidgetArea";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Widget, View } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import NextButton from "/app/src/components/NextUi/Button";

export default function Widgets({
  view,
  editingView,
}: {
  view: View;
  editingView: boolean;
}) {
  const { t } = useTranslation();
  const [locked, setLocked] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const { isAuthenticated } = useAuthState();
  const queryClient = useQueryClient();

  // Update Widget in list

  //toggling the movement to on should set editing to false
  const toggleLocked = () => {
    if (locked) {
      setIsEditing(false);
    }
    setLocked((prev) => !prev);
  };

  //toggling editing to on should set the movement to locked
  const toggleIsEditing = () => {
    if (!isEditing) {
      setLocked(true);
    }
    setIsEditing((prev) => !prev);
  };

  // if the user toggles editing view to false,
  // ensure the widget editing is disabled and movement
  // is locked
  useEffect(() => {
    if (editingView === false) {
      setIsEditing(false);
      setLocked(true);
    }
  }, [editingView]);

  const { data: widgets } = useQuery({
    queryKey: ["widgets", view.id],
    queryFn: () => {
      return widgetService.getAll(buildParams({ viewId: view.id }));
    },
    select: (response: { widgets: Widget[] }) => response.widgets,
    initialData: { widgets: [] as Widget[] },
    enabled: Boolean(view.id),
  });

  const { mutateAsync: createWidget } = useMutation({
    mutationFn: (widget: Omit<Widget, "datasets">) => {
      return widgetService.createSingle(widget).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["widgets", view.id],
        (oldData: { widgets: Widget[] }) => {
          return { widgets: [...oldData.widgets, response.widget] };
        },
      );
    },
  });

  const handleCreateClick = useCallback(() => {
    createWidget({ viewId: view.id });
  }, [createWidget, view.id]);

  return (
    <div className="dashboard" style={{ paddingTop: "15px" }}>
      {editingView && (
        <Row gutter={10} justify="end">
          <Col>
            <NextButton color="primary" size="sm" onClick={handleCreateClick}>
              {t("translation:add_new_widget")}
            </NextButton>
          </Col>
          <Col>
            <NextButton
              size="sm"
              color={locked || isEditing ? "default" : "primary"}
              variant={locked || isEditing ? "bordered" : "solid"}
              className={
                (locked || isEditing) &&
                "bg-white hover:border-blue-500 hover:text-blue-500"
              }
              onClick={toggleLocked}
            >
              {locked || isEditing ? (
                <>
                  <LockOutlined /> {t("translation:edit_layout")}
                </>
              ) : (
                <>
                  <UnlockOutlined /> {t("translation:finish_editing")}
                </>
              )}
            </NextButton>
          </Col>
          <Col>
            <NextButton
              size="sm"
              color={!isEditing ? "default" : "primary"}
              variant={!isEditing ? "bordered" : "solid"}
              className={
                !isEditing &&
                "bg-white hover:border-blue-500 hover:text-blue-500"
              }
              onClick={toggleIsEditing}
            >
              {isEditing ? (
                <>
                  <UnlockOutlined /> {t("translation:finish_editing")}
                </>
              ) : (
                <>
                  <LockOutlined /> {t("translation:edit_widgets")}
                </>
              )}
            </NextButton>
          </Col>
        </Row>
      )}
      {isAuthenticated && Boolean(widgets) ? (
        <WidgetArea widgets={widgets} isEditing={isEditing} locked={locked} />
      ) : null}
    </div>
  );
}
