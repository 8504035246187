import { useCallback } from "react";
import ConfirmationMappingTypes from "./confirmationMappingTypes";
import { ConfirmationType, Mapping } from "/app/src/models";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import EditGroupingConfirmationMapping from "./editGroupingConfirmationMapping";
import NextButton from "/app/src/components/NextUi/Button";

interface FormValues {
  key: string;
  value: string;
  type: `confirmation-${Lowercase<ConfirmationType>}`;
}

/**
 * Component for editing a confirmation mapping. If the mapping is a grouping type, it will also display the child mappings
 */
export default function EditConfirmationMapping({
  mapping,
  updateMapping,
  deleteMapping,
  mappings,
}: {
  mapping: Mapping;
  updateMapping: (mapping: Omit<Mapping, "parentMapping" | "children">) => void;
  deleteMapping: (mapping: Omit<Mapping, "parentMapping" | "children">) => void;
  mappings: Mapping[];
}) {
  const { t } = useTranslation();

  /**
   * Calls the updateMapping function with the values from the form
   */
  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await updateMapping({
        value: values.value,
        key: values.key,
        id: mapping.id,
      });
      return;
    },
    [mapping.id, updateMapping],
  );

  /**
   * Calls the deleteMapping function with the mapping
   */
  const handleDelete = useCallback(async () => {
    await deleteMapping(mapping);
  }, [deleteMapping, mapping]);

  return (
    <>
      <Row justify="start" gutter={16}>
        <Col span={21}>
          <ConfirmationMappingTypes
            mappings={mappings}
            mapping={mapping}
            onSubmit={handleSubmit}
          />
        </Col>
        <Col span={3}>
          <NextButton
            onClick={handleDelete}
            className="bg-[#a12121] text-white"
            fullWidth
          >
            {t("translation:remove")}
          </NextButton>
        </Col>
      </Row>
      {mapping.confirmationType === "Grouping" && (
        <EditGroupingConfirmationMapping
          mapping={mapping}
          mappings={mappings}
        />
      )}
    </>
  );
}
