import { useContext } from "react";
import { appService } from "/app/src/services";
import ConnectionsStatusContext from "/app/src/contexts/ConnectionsStatusContext";

import OrderBuilder from "../orderBuilder";
import OrderProcessor from "../orderProcessor";
import Epicor from "../epicor";
import Hue from "../hue";
import CycleCounts from "../cycleCounts";
import Variance from "../variance";
import Slack from "../slack";
import Displays from "../displays";
import DisabledCheck from "/app/src/components/generic/components/disabled";
import DataPush from "../dataPush";
import { App } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";
import { useQuery } from "@tanstack/react-query";
import DataPull from "../dataPull";

/**
 * Component to map the app name to the correct component
 */
export default function SingleApp() {
  const { appId } = useNumberId();
  const { powerPick, webServices } = useContext(ConnectionsStatusContext);

  const { data: app, isFetching } = useQuery({
    queryKey: ["app", appId],
    queryFn: () => appService.getSingle(appId),
    initialData: { app: {} },
    select: (data: { app: App }) => data.app,
  });
  if (isFetching) return null;
  else {
    switch (app.name) {
      case "Order Builder":
        return (
          <DisabledCheck
            name="Order Builder"
            message="Connection to Power Pick failed. Order Builder Disabled."
            connections={[powerPick, webServices]}
          >
            <OrderBuilder app={app} />
          </DisabledCheck>
        );
      case "Order Processor":
        return (
          <DisabledCheck
            name="Order Processor"
            message="Connection to Power Pick failed. Order Processor Disabled."
            connections={[webServices, powerPick]}
          >
            <OrderProcessor app={app} />
          </DisabledCheck>
        );
      case "Epicor":
        return <Epicor app={app} />;
      case "Philips Hue":
        return <Hue app={app} />;
      case "Cycle Counts":
        return <CycleCounts app={app} />;
      case "Variance":
        return <Variance app={app} />;
      case "Slack":
        return <Slack app={app} />;
      case "Displays":
        return <Displays app={app} />;
      case "Data Push":
        return <DataPush app={app} />;
      case "Data Pull":
        return <DataPull app={app} />;
      default:
        return app.name;
    }
  }
}
