import { appService } from "/app/src/services";
import GenericSettings from "./generic";
import Hue from "./hue";
import { App as AppType } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";
import { useQuery } from "@tanstack/react-query";
import OrderBuilderStatuses from "./orderBuilder";

export default function App() {
  const params = useNumberId();

  const { data: app } = useQuery({
    queryKey: ["app", params.appId],
    queryFn: () => {
      return appService.getSingle(params.appId);
    },
    enabled: Boolean(params.appId),
    initialData: { app: {} },
    select: (data: { app: AppType }) => {
      return data.app;
    },
  });

  return (
    <div className="appSettings">
      <div className="box">
        {app.name === "Philips Hue" ? (
          <Hue app={app} />
        ) : app.name === "Order Builder" ? (
          <OrderBuilderStatuses />
        ) : (
          <GenericSettings app={app} />
        )}
      </div>
    </div>
  );
}
