import React from "react";
import { Col, Row } from "antd";
import { Form, SubmitButton, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { WorkflowAction, Integration, App } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { reportService } from "/app/src/services";
import { buildParams, simpleSchemaBuilder } from "/app/src/helpers";
import NextButton from "/app/src/components/NextUi/Button";
export default function DataPush({
  app,
  action,
  addWorkflowAction,
  integrations,
  removeWorkflowAction,
  updateAction,
  isNew,
  resetSelectedApp,
}: {
  app: App;
  action: WorkflowAction;
  addWorkflowAction: (action: WorkflowAction) => Promise<any>;
  integrations: Integration[];
  removeWorkflowAction: (action: WorkflowAction) => Promise<any> | undefined;
  updateAction: (action: WorkflowAction) => Promise<any>;
  isNew: boolean;
  resetSelectedApp: () => void;
}) {
  const { t } = useTranslation();
  const { data: reports } = useQuery({
    queryKey: ["historyReports"],
    queryFn: () => {
      return reportService.getAll(buildParams({ baseTable: "History" }));
    },
    initialData: { reports: [] },
    select: (data) => data.reports,
  });
  return (
    <Row justify="start" gutter={16}>
      <Col span={24}>
        <Formik
          enableReinitialize
          initialValues={{
            ...(isNew
              ? { integrationId: undefined, reportId: undefined }
              : {
                  integrationId: action.integrationId,
                  reportId: action.reportId,
                }),
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "integrationId", type: "number", required: true },
            { name: "reportId", type: "number", required: true },
          ])}
          onSubmit={(values, actions) => {
            if (isNew) {
              const newAction = { ...values, appId: app.id } as WorkflowAction;

              addWorkflowAction(newAction).then((response) => {
                if (!response?.errors) {
                  actions.resetForm();
                  resetSelectedApp();
                }
              });
            } else {
              if (action?.id) {
                updateAction({ id: action.id, ...values }).then(() => {
                  actions.resetForm();
                });
              }
            }
          }}
        >
          {({ dirty }) => (
            <Form>
              <Row justify="start" gutter={16}>
                <Col span={3}>
                  <span className="blockText">{t("translation:run")}</span>
                </Col>
                <Col span={8}>
                  <Form.Item name="integrationId">
                    <Select
                      name="integrationId"
                      size="large"
                      placeholder={`${t("translation:select_integration")} ${t(
                        "translation:to_run",
                      )}`}
                    >
                      {integrations.map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.name} - {c.number}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="reportId">
                    <Select
                      name="reportId"
                      size="large"
                      placeholder={t("translation:select_report")}
                    >
                      {reports.map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col flex="auto">
                  <SubmitButton
                    type="primary"
                    size="large"
                    block
                    disabled={!dirty}
                  >
                    {isNew
                      ? t("translation:add_action")
                      : t("translation:save")}
                  </SubmitButton>
                </Col>
                {!isNew && (
                  <Col span={3}>
                    <NextButton
                      size="md"
                      variant="bordered"
                      color="default"
                      fullWidth
                      onClick={() => removeWorkflowAction(action)}
                      className="hover:border-blue-500 hover:text-blue-500 bg-white"
                    >
                      {t("translation:remove")}
                    </NextButton>
                  </Col>
                )}
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
