import React, { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";

import { appService } from "/app/src/services";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { App as AppType } from "/app/src/models";

interface FormValues {
  user: string | undefined;
  token: string | undefined;
}

function formatForm(app: AppType) {
  const formatted = {
    user: app.user,
    token: app.token,
  };
  return formatted;
}

export default function GenericSettings({ app }: { app: AppType }) {
  const { t } = useTranslation();

  const settingsForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form layout="vertical">
          <h3>
            {app.name} - {t("translation:authentication")}
          </h3>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item name="user" label={t("translation:user")}>
                <Input name="user" className="user" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="token" label={t("translation:token")}>
                <Input name="token" className="token" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={24}>
              <SubmitButton type="primary" size="large" block disabled={!dirty}>
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [app.name, t],
    );

  return (
    <div className="appSettings">
      <Formik
        component={settingsForm}
        initialValues={{
          user: app.user,
          token: app.token,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          if (!app.id) return;
          appService
            .updateSingle(app.id, formatForm(values))
            .then((response) => {
              if ("errors" in response) {
                handleSubmissionErrors(response.errors, actions.setFieldError);
                actions.setSubmitting(false);
              } else {
                actions.setSubmitting(false);
              }
            });
        }}
      />
    </div>
  );
}
