import React, { useCallback } from "react";
import { Formik } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, Input, Checkbox } from "formik-antd";
import { useTranslation } from "react-i18next";
import { View } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";

/**
 * Component to create a new view. Includes a form to enter the view name, set view privacy, and select a template.
 * @param createView function to create a new view
 * @param userViews list of user's views
 * @param sharedViews list of shared views
 */
export default function NewView({
  createView,
  userViews,
  sharedViews,
}: {
  createView: (view: View) => void;
  userViews: View[];
  sharedViews: View[];
}) {
  const { t } = useTranslation();

  const onViewSubmit = useCallback(
    (values, actions) => {
      if (values.viewId === "Blank") {
        values.viewId = null;
      }
      actions.setSubmitting(false);
      //user selects if view is shared, so set value to opposite
      values.isPrivate = !values.isPrivate;
      createView(values);
    },
    [createView],
  );

  return (
    <div className="box">
      <div className="newIntegration">
        <Formik
          initialValues={{
            name: "",
            viewId: "Blank",
            isPrivate: false,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
            { name: "viewId", type: "string", required: false },
          ])}
          onSubmit={onViewSubmit}
        >
          <Form layout="vertical">
            <Row justify="start" gutter={16}>
              <Col span={6}>
                <Form.Item
                  name="name"
                  label={t("translation:view_name")}
                  hasFeedback={false}
                >
                  <Input
                    name="name"
                    placeholder={t("translation:enter_name")}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="isPrivate"
                  label={t("translation:shared_view")}
                >
                  <Checkbox name="isPrivate">
                    {t("translation:make_shared")}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="viewId" label={t("translation:view_template")}>
                  <Select
                    name="viewId"
                    size="large"
                    placeholder={t("translation:select_template")}
                  >
                    <Select.Option value="Blank" key={0}>
                      {t("translation:blank")}
                    </Select.Option>
                    <Select.OptGroup label={t("translation:your_views")}>
                      {userViews.map((v) => (
                        <Select.Option value={v.id} key={v.id}>
                          {v.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label={t("translation:shared_views")}>
                      {sharedViews.map((v) => (
                        <Select.Option value={v.id} key={v.id}>
                          {v.name} - {v.username}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <SubmitButton
                  type="primary"
                  size="large"
                  block
                  style={{ marginTop: "30px" }}
                >
                  {t("translation:create")} {t("translation:view")}
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
