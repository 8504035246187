import React, { useEffect } from "react";
import { Skeleton, Spin } from "antd";
import Pagination from "./pagination";
import { useTranslation } from "react-i18next";
import { Sort as SortType } from "/app/src/types";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
export default function Table({
  rows,
  tableColumns,
  length,
  sort,
  setSort,
  rowClicked,
  emptyText,
  enableRowSelection = false,
  setSelectedRows = () => {},
  paginationEnabled,
  loading = false,
  classStyle = "dataTable",
}: {
  rows: any[];
  tableColumns: any[];
  length: number;
  sort: SortType;
  setSort: React.Dispatch<React.SetStateAction<SortType>>;
  rowClicked?: (key: number) => void;
  emptyText: string;
  setSelectedRows?: (rows?: Record<string, boolean>) => void;
  loading?: boolean;
  enableRowSelection?: boolean;
  paginationEnabled?: {
    setPageSize: (pageSize: number) => void;
    setPage: (page: number) => void;
    currentPage: number;
    pageSize: number;
  };
  classStyle?: string;
}) {
  const { t } = useTranslation();
  const {
    state: { selectedRowIds },
    getTableBodyProps,
    headerGroups,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    state: { sortBy },
    prepareRow,
  } = useTable(
    {
      columns: tableColumns,
      data: rows,
      initialState: {
        sortBy: sort,
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      manualSortBy: true,
      disableSortBy: loading,
    },
    useSortBy,
    usePagination,
    useRowSelect,
  );
  useEffect(() => {
    if (enableRowSelection) {
      setSelectedRows(selectedRowIds);
    }
  }, [selectedRowIds, setSelectedRows, enableRowSelection]);
  // Store new sort state  and call API to fetch new data from server
  useEffect(() => {
    if (sort !== sortBy) {
      setSort(sortBy);
    }
  }, [sortBy, setSort, sort]);

  //NOTES RE: SPINNER
  //length is initialized to -1, so if it is -1, then the fetch is
  //still occurring.

  return (
    <>
      <div className={classStyle}>
        {length === null || loading ? (
          <>
            <table className="table table-striped table-bordered">
              <thead>
                {headerGroups.map((headerGroup) => (
                  // Header group has no unique property. Set to 1 as there is only 1 header group.
                  <tr key={1} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th
                        key={headerGroup.id}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                      >
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? sort[0]?.desc === true
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            </table>
            <Spin>
              <Skeleton title={false} paragraph={{ rows: 14, width: 100 }} />
            </Spin>
          </>
        ) : length === 0 ? (
          <TableEmpty emptyText={emptyText} />
        ) : tableColumns.length === 0 ? (
          <TableEmpty emptyText={t("translation:no_columns_selected")} />
        ) : (
          <table className="table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={1} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th
                        key={column.id}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                      >
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? sort[0]?.desc === true
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return rowClicked ? (
                  <tr key={1} className="rowHover" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        /// check if the cell prop is clickable. If it isn't, set the width and use no onclick handler
                        //width at 1% found here: https://stackoverflow.com/a/11267268
                        <td
                          //Combined column id and row id to make a unique key for each cell
                          key={`${cell.column.id}-${cell.row.id}`}
                          {...cell.getCellProps()}
                          style={{
                            width: cell.column?.clickable !== false ? "" : "1%",
                          }}
                          onClick={() => {
                            if (cell.column?.clickable !== false) {
                              rowClicked(row.original?.id as number);
                            }
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                ) : (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        //Combined column id and row id to make a unique key for each cell
                        <td
                          key={`${cell.column.id}-${cell.row.id}`}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {paginationEnabled && (
        <Pagination
          length={length}
          pageSize={paginationEnabled.pageSize}
          setPageSize={paginationEnabled.setPageSize}
          currentPage={paginationEnabled.currentPage}
          setPage={paginationEnabled.setPage}
        />
      )}
    </>
  );
}

export function TableEmpty({ emptyText }: { emptyText: string }) {
  return (
    <div className="reportLoading text-center">
      {emptyText && <span className="loadingText">{emptyText}</span>}
    </div>
  );
}
