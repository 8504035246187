import { useCallback, useEffect } from "react";
import axios from "axios";
import {
  useAuthState,
  useAuthDispatch,
  logout,
  refreshUser,
} from "/app/src/contexts/authentication";
import { useMsal } from "@azure/msal-react";

export const AxiosWrapper = () => {
  const { user, authType } = useAuthState();
  const dispatch = useAuthDispatch();
  const { instance } = useMsal();

  const addAzureAuth = useCallback(() => {
    const activeAccount = instance.getActiveAccount();
    if (activeAccount) {
      const cache = instance.getTokenCache();
      // @ts-expect-error - MSAL does not expose this method
      const idTokenEntity = cache.storage.getIdToken(
        activeAccount.homeAccountId,
      );
      return `Bearer ${idTokenEntity?.secret}`;
    }
    return "";
  }, [instance]);

  axios.defaults.validateStatus = (status) => {
    return status < 500;
  };

  // Remove the existing interceptor when either the user or refreshAccessToken change.
  useEffect(() => {
    const authInterceptor = axios.interceptors.request.use(
      (config) => {
        if (authType === "azure") {
          config.headers.Authorization = addAzureAuth();
        } else {
          if (config?.url && config.url.includes("refresh")) {
            if (user.refreshToken && config?.headers) {
              config.headers.Authorization = `Bearer ${user.refreshToken}`;
            }
          } else {
            if (user.accessToken && config?.headers) {
              config.headers.Authorization = `Bearer ${user.accessToken}`;
            }
          }
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );
    return () => {
      axios.interceptors.request.eject(authInterceptor);
    };
  }, [user, addAzureAuth, authType]);

  useEffect(() => {
    const respInterceptor = axios.interceptors.response.use((response) => {
      if (response.status === 401) {
        logout(dispatch);
        return Promise.reject(response.data);
      } else {
        return response;
      }
    });
    return () => {
      axios.interceptors.response.eject(respInterceptor);
    };
  }, [user, dispatch]);

  useEffect(() => {
    if (authType === "basic") {
      if (user?.refreshToken) {
        refreshUser(dispatch, user.refreshToken);
      }
      const id = setInterval(() => {
        if (user?.refreshToken) {
          refreshUser(dispatch, user.refreshToken);
        }
      }, 150000); // 2.5 minutes
      return () => {
        clearInterval(id);
      };
    } else {
      instance.acquireTokenSilent({ scopes: ["email", "openid", "profile"] });
      const id = setInterval(() => {
        instance.acquireTokenSilent({ scopes: ["email", "openid", "profile"] });
      }, 15000); // 2.5 minutes
      return () => {
        clearInterval(id);
      };
    }
  }, [dispatch, user.refreshToken, authType, instance]);
  return null;
};
