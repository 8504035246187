import APIStatusChart from "./apiStatusChart";
import StatusHook from "/app/src/components/status/statusHook";

/**
 * Component for displaying api status
 * @returns JSX.Element
 *
 */
export default function ApiStatus() {
  const { apiConnectionCounts } = StatusHook();

  return (
    <div>
      <div className="box">
        <APIStatusChart data={apiConnectionCounts} />
      </div>
    </div>
  );
}
