import { StarFilled, StarOutlined } from "@ant-design/icons";
import React, { useCallback } from "react";

/**
 * Component to display a star icon that can be clicked to favourite/unfavourite an object
 * @param param0
 * favouriteFn: (id: number) => void;
 * unfavouriteFn: (id: number) => void;
 * isFavourite: boolean;
 * objectId: number;
 */
export default function FavouriteStar({
  favouriteFn,
  unfavouriteFn,
  isFavourite,
  objectId,
}: {
  favouriteFn: (id: number) => void;
  unfavouriteFn: (id: number) => void;
  isFavourite: boolean;
  objectId: number;
}) {
  const handleFavouriteClick = useCallback(() => {
    if (isFavourite) {
      unfavouriteFn(objectId);
    } else {
      favouriteFn(objectId);
    }
  }, [isFavourite, favouriteFn, unfavouriteFn, objectId]);

  if (isFavourite) {
    return (
      <StarFilled
        onClick={handleFavouriteClick}
        style={{ color: "#FFD700", fontSize: "150%" }} // skipcq: JS-0394
      />
    );
  } else {
    return (
      <StarOutlined
        onClick={handleFavouriteClick}
        style={{ color: "#FFD700", fontSize: "150%" }} // skipcq: JS-0394
      />
    );
  }
}
