import { useState, useEffect } from "react";

import { integrationService } from "/app/src/services";
import BatchSerialNumbers from "../batchSerialNumbers";
import ManualProcessor from "../manualProcessor";
import { App, Integration } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";
export default function SingleProcessor({ app }: { app: App }) {
  const { integrationId } = useNumberId();
  const [integration, setIntegration] = useState<Integration>({});
  useEffect(() => {
    if (integrationId) {
      integrationService
        .getSingle(integrationId)
        .then((response) => setIntegration(response.integration))
        .catch((error) => {
          console.log(error);
        });
    }
  }, [integrationId]);

  if (integration.name === "Batch Serial Number") {
    return (
      <BatchSerialNumbers integrationId={integration.id as number} app={app} />
    );
  } else if (integration.name === "Manual Processing") {
    return (
      <ManualProcessor integrationId={integration.id as number} app={app} />
    );
  } else {
    return null;
  }
}
