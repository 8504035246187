import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Connection, Log } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { logService } from "/app/src/services";
import { buildCreationDateQuery, buildParams } from "/app/src/helpers";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import Controls from "./_controls";
import getOrderByQuery from "/app/src/helpers/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { createColumnHelper } from "@tanstack/react-table";
import StatusIcon from "/app/src/components/generic/components/statusIcon";

/**
 * Component for displaying the logs of a connection
 * @param connection the connection to display the logs for
 */
export default function Logs({
  connection,
  isPowerPickConnection,
}: {
  connection: Connection;
  isPowerPickConnection?: boolean;
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");
  const [beforeTime, setBeforeTime] = useState("");
  const [afterTime, setAfterTime] = useState("");
  const { t } = useTranslation();

  const { data, isFetching } = useQuery({
    queryKey: [
      "logsByConnection",
      connection.id,
      page,
      pageSize,
      searchString,
      beforeTime,
      afterTime,
      sort,
    ],
    queryFn: () => {
      const creationDateQuery = buildCreationDateQuery(beforeTime, afterTime);
      const params = buildParams({
        connectionId: connection.id,
        page,
        limit: pageSize,
        search: searchString,
        creationDate: creationDateQuery,
        orderby: getOrderByQuery(sort),
      });
      return logService.getAll(params);
    },
    initialData: { logs: [] },
  });

  const { data: logLength } = useQuery({
    queryKey: [
      "logsCountByConnection",
      connection.id,
      beforeTime,
      afterTime,
      searchString,
    ],
    queryFn: () => {
      const creationDateQuery = buildCreationDateQuery(beforeTime, afterTime);
      const params = buildParams({
        connectionId: connection.id,
        search: searchString,
        creationDate: creationDateQuery,
      });
      return logService.getCount(params);
    },
    initialData: { count: 0 },
  });

  /**
   * Converts the data to json and then returns the value
   * of the passed in key
   * @param data json string
   * @param key key to return the value of
   */
  function parseOutData(data: string, key: string) {
    try {
      const parsedData = JSON.parse(data);
      //convert to string to avoid react error
      return JSON.stringify(parsedData[key]);
    } catch (e) {
      return data;
    }
  }

  const columnHelper = createColumnHelper<Log>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => {
          return <DateTime date={info.getValue()} />;
        },
        header: t("translation:creationDate"),
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => {
          return <p>{parseOutData(info.getValue(), "Sent")}</p>;
        },
        header: t("translation:sent_data_info"),
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => {
          return <p>{parseOutData(info.getValue(), "Received")}</p>;
        },
        header: t("translation:response_info"),
      }),
    ],
    [columnHelper, t],
  );
  const powerpickColumns = useMemo(
    () => [
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => {
          return <DateTime date={info.getValue()} />;
        },
        header: t("translation:creationDate"),
      }),
      columnHelper.accessor("error", {
        id: "error",
        cell: (info) => {
          return <p>{parseOutData(info.getValue(), "error")}</p>;
        },
        header: t("translation:error"),
      }),
      columnHelper.accessor("status", {
        id: "status",
        cell: (info) => {
          const value = info.getValue();
          return <StatusIcon status={value} />;
        },
        header: t("translation:status"),
      }),
    ],
    [columnHelper, t],
  );

  return (
    <div>
      <Controls
        setSearchString={setSearchString}
        setBeforeTime={setBeforeTime}
        setAfterTime={setAfterTime}
      />
      <TableUpgrade
        rows={data.logs}
        tableColumns={isPowerPickConnection ? powerpickColumns : columns}
        length={logLength.count}
        sort={sort}
        loading={isFetching}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        emptyText={t("translation:no_logs_found")}
        classStyle="dataTable ghost"
      />
    </div>
  );
}
