import { Col, Row } from "antd";
import Role from "./role";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Role as RoleType } from "/app/src/models";

export default function Roles({ roles }: { roles: RoleType[] }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="userGroupMenu sideMenu">
      <div className="box">
        <Row>
          <Col span={18}>
            <h2>{t("translation:role_details")}</h2>
          </Col>
          <Col span={6}>
            <PlusCircleOutlined
              style={{ float: "right", fontSize: "28px", color: "#1890ff" }}
              onClick={() => {
                navigate("/users/roles/new");
              }}
            />
          </Col>
        </Row>
        <ul>
          {roles.map((role) => (
            <Role role={role} key={role.id} />
          ))}
        </ul>
      </div>
    </div>
  );
}
