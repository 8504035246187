import { Row, Col } from "antd";
import NextDivider from "/app/src/components/NextUi/Divider";
import "./_status.scss";
import { useTranslation } from "react-i18next";
import StatusHook from "./statusHook";
import ToolTip from "../generic/components/toolTip";

/**
 * Component for displaying performance
 * @returns JSX.Element
 *
 */
export default function Performance() {
  const { t } = useTranslation();
  const {
    apiCallsByMonth,
    workflowsCount,
    snapshotCount,
    reportCount,
    fileCount,
  } = StatusHook();

  return (
    <div className="box">
      <Row>
        <Col span={12}>
          <div className="title">
            {t("translation:api_calls_month")}
            <ToolTip tip={t("translation:api_calls_30")} />
          </div>
        </Col>
        <Col span={12}>
          <div className="title">{apiCallsByMonth}</div>
        </Col>
      </Row>
      <Col span={24}>
        <NextDivider className="my-6" />
      </Col>
      <Row>
        <Col span={12}>
          <div className="title">
            {t("translation:files_this_month")}
            <ToolTip tip={t("translation:files_last_30")} />
          </div>
        </Col>
        <Col span={12}>
          <div className="title">{fileCount}</div>
        </Col>
      </Row>
      <Col span={24}>
        <NextDivider className="my-6" />
      </Col>
      <Row>
        <Col span={12}>
          <div className="title">
            {t("translation:workflows")}
            <ToolTip tip={t("translation:active_workflows")} />
          </div>
        </Col>
        <Col span={12}>
          <div className="title">{workflowsCount}</div>
        </Col>
      </Row>
      <Col span={24}>
        <NextDivider className="my-6" />
      </Col>
      <Row>
        <Col span={12}>
          <div className="title">{t("translation:reports")}</div>
        </Col>
        <Col span={12}>
          <div className="title">{reportCount}</div>
        </Col>
      </Row>
      <Col span={24}>
        <NextDivider className="my-6" />
      </Col>
      <Row>
        <Col span={12}>
          <div className="title">{t("translation:snapshots")}</div>
        </Col>
        <Col span={12}>
          <div className="title">{snapshotCount}</div>
        </Col>
      </Row>
    </div>
  );
}
