import React from "react";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Tip from "/app/src/components/NextUi/Tip";

/**
 * ToolTip component
 * @param param0 tip, icon, fontSize
 * @returns NextUI ToolTip component wrapped in antd icons
 */
export default function ToolTip({
  tip = "",
  icon = "question",
  fontSize = "",
}: {
  tip: string;
  icon?: "question" | "info" | "warning";
  fontSize?: string;
}) {
  if (icon === "question") {
    return (
      <Tip content={tip}>
        <QuestionCircleOutlined style={{ paddingLeft: "5px", fontSize }} />
      </Tip>
    );
  } else if (icon === "info") {
    return (
      <Tip content={tip}>
        <InfoCircleOutlined style={{ paddingLeft: "5px", fontSize }} />
      </Tip>
    );
  } else if (icon === "warning") {
    return (
      <Tip content={tip}>
        <ExclamationCircleOutlined
          style={{ paddingLeft: "5px", color: "orange", fontSize }}
        />
      </Tip>
    );
  }
}
