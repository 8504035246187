import { Formik, FormikHelpers, FormikProps } from "formik";
import { useCallback, useMemo } from "react";
import { Form, SubmitButton, Select } from "formik-antd";
import ToolTip from "/app/src/components/generic/components/toolTip";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import useSetting from "/app/src/components/settings/setting";

interface FormValues {
  value: string | undefined;
}

/**
 * Component to display the request settings for a single Data Pull Integration
 * The request settings are the method and body of the request. Current available
 * methods are GET and POST
 */
export default function MethodSetting({
  params,
}: {
  params: { [key: string]: string | number };
}) {
  const { t } = useTranslation();
  const { settingValue, createUpdateSetting } = useSetting(params);

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await createUpdateSetting({ ...values, ...params }).then(() => {
        actions.resetForm();
      });
    },
    [createUpdateSetting, params],
  );
  const methodLabelContent = useMemo(
    () => (
      <>
        {t("translation:method")}
        <ToolTip tip={t("translation:method_tip")} />
      </>
    ),
    [t],
  );

  const methodLabelForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item
                name="value"
                label={methodLabelContent}
                hasFeedback={false}
              >
                <Select name="value" size="large">
                  <Select.Option value="POST">
                    {t("translation:post")}
                  </Select.Option>
                  <Select.Option value="PATCH">
                    {t("translation:patch")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [methodLabelContent, t],
    );

  return (
    <div>
      <Formik
        initialValues={
          settingValue ? { value: settingValue } : { value: "POST" }
        }
        onSubmit={onSubmit}
        enableReinitialize
        component={methodLabelForm}
      />
    </div>
  );
}
