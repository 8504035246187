import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { integrationService } from "/app/src/services";
import { useCallback, useState } from "react";
import NextButton from "/app/src/components/NextUi/Button";

/**
 * Component to display the settings for a single Data Pull Integration
 */
export default function Test({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const [testResponse, setTestResponse] = useState<string | null>(null);

  /**
   * Test the integration
   */
  const handleTest = useCallback(async () => {
    try {
      const response = await integrationService.testIntegration(integration.id);
      setTestResponse(JSON.stringify(response?.data));
    } catch (error) {
      setTestResponse(error.message);
    }
  }, [integration]);

  return (
    <div className="box">
      <Row>
        <Col span={23}>
          <h1>{t("translation:test")}</h1>
          <NextButton color="primary" size="sm" onClick={handleTest}>
            {t("translation:test")}
          </NextButton>
          {testResponse && <p>{testResponse}</p>}
        </Col>
      </Row>
    </div>
  );
}
