import React from "react";
import { useTranslation } from "react-i18next";
import NextDivider from "../../../NextUi/Divider";
import { Integration } from "/app/src/models";
import GeneralSettings from "/app/src/components/apps/orderBuilder/settings/generalSettings";
/**
 * Component to display the settings for a single Data Pull or Data Push Integration
 */
export default function Settings({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  return (
    <>
      <h3 className="font-bold text-base">{t("translation:settings")}</h3>
      <NextDivider />
      <GeneralSettings integration={integration} />
    </>
  );
}
