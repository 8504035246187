import { Link, useLocation } from "react-router-dom";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import { useAuthState } from "/app/src/contexts/authentication";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  const { authType, isAuthenticated } = useAuthState();
  const { version, message, licensedTo, licenseExpiry, loading, releaseDate } =
    useStatusContext();
  const location = useLocation();
  const currentRoute = location.pathname;

  if (authType === "azure" && !isAuthenticated) {
    return (
      <footer>
        <div style={{ textAlign: "center" }}>
          <span>
            {t("translation:version")} <strong>{version}</strong> |{" "}
            {t("translation:release_date")} <strong>{releaseDate}</strong>
          </span>
        </div>
      </footer>
    );
  } else if (loading || message === "restarting") {
    return <footer />;
  } else if (currentRoute === "/users/forgot") {
    return (
      <footer>
        <div style={{ textAlign: "center" }}>
          {version ? (
            <span>
              {t("translation:version")} <strong>{version} | </strong>
            </span>
          ) : (
            <span />
          )}
          <span>
            <Link to="/login">{t("translation:return_to_login")}</Link>
          </span>
        </div>
      </footer>
    );
  } else {
    return (
      <footer>
        {!loading &&
        isAuthenticated &&
        message !== "invalid" &&
        message !== "locked" ? (
          <div className="footerMenu">
            <div className="left">
              {version ? (
                <span>
                  {t("translation:version")} <strong>{version}</strong> |{" "}
                  {t("translation:release_date")} <strong>{releaseDate}</strong>{" "}
                  | {t("translation:licensed_to")} <strong>{licensedTo}</strong>{" "}
                  | {t("translation:license_expires")}{" "}
                  <strong>{licenseExpiry}</strong>
                </span>
              ) : (
                <span />
              )}
            </div>
            <div className="right">
              <Link to="/status">{t("translation:status")}</Link>
              <Link to="/settings">{t("translation:settings")}</Link>
              <Link to="/logs">{t("translation:logs")}</Link>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            {version ? (
              <span>
                {t("translation:version")} <strong>{version} | </strong>
              </span>
            ) : (
              <span />
            )}
            {message !== "invalid" && message !== "locked" && (
              <span>
                <Link to="/users/forgot">
                  {t("translation:forgot_password")}
                </Link>
              </span>
            )}
          </div>
        )}
      </footer>
    );
  }
}
