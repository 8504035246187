import React from "react";
import { Row, Col, Input, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { handleRangePickerChange } from "/app/src/helpers";
import NextButton from "/app/src/components/NextUi/Button";
const { RangePicker } = DatePicker;
type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];
export default function InnerControls({
  count,
  setSearchString,
  updateTransactions,
  ordersRetrying,
  refetch,
  setAfterTime,
  setBeforeTime,
}: {
  count: number;
  setSearchString: (searchString: string) => void;
  updateTransactions: () => void;
  ordersRetrying: string[];
  refetch: () => void;
  setAfterTime: (time: string) => void;
  setBeforeTime: (time: string) => void;
}) {
  const { t } = useTranslation();
  //handler for user changing the range of dates
  const onOk = (value: RangeValue) => {
    handleRangePickerChange(value, setBeforeTime, setAfterTime);
  };
  return (
    <Row gutter={[10, 10]} style={{ paddingBottom: "10px" }}>
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 12, order: 1 }}
        lg={{ span: 12, order: 1 }}
        xl={{ span: 12, order: 1 }}
      >
        <Row gutter={20}>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 12, order: 1 }}
          >
            <Input.Search
              onSearch={(value) => setSearchString(value)}
              size="large"
              placeholder={t("translation:search")}
            />
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 12, order: 1 }}
          >
            {" "}
            <RangePicker
              allowClear
              allowEmpty={[true, true]}
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              onChange={(value) => onOk(value)}
              size="large"
            />
          </Col>
        </Row>
      </Col>

      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 12, order: 2 }}
        lg={{ span: 12, order: 2 }}
        xl={{ span: 12 }}
      >
        <div className="controlButtons">
          <NextButton
            color="primary"
            size="md"
            onClick={() => {
              refetch();
            }}
            isDisabled={ordersRetrying.length > 0}
          >
            {t("translation:refresh")}
          </NextButton>
          <NextButton
            color="primary"
            size="md"
            onClick={() => {
              updateTransactions();
            }}
            isDisabled={!count || ordersRetrying.length > 0}
          >
            {`${t("translation:resend")} ${count}`}
          </NextButton>
        </div>
      </Col>
    </Row>
  );
}
