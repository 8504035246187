import React from "react";
import { Helmet } from "react-helmet";
import NextSkeleton from "/app/src/components/NextUi/Skeleton";
import { Row, Col } from "antd";

import { workflowService } from "/app/src/services";
import { Workflow as WorkflowType } from "/app/src/models";
import WorkflowView from "./workflowView";
import { useQuery } from "@tanstack/react-query";
import { useNumberId } from "../../hooks/useNumberId";

export default function Workflow() {
  const { workflowId } = useNumberId();

  // Get API data
  const { data: workflow, isFetching } = useQuery({
    queryKey: ["workflow", workflowId],
    queryFn: () => {
      return workflowService.getSingle(workflowId);
    },
    enabled: Boolean(workflowId),
    initialData: { workflow: {} },
    select: (data: { workflow: WorkflowType }) => {
      return data.workflow;
    },
  });

  return (
    <div className="workflow">
      <Helmet>
        <title>{workflow?.name}</title>
      </Helmet>
      {isFetching ? (
        <Row className="pt-6" gutter={20}>
          <Col span={17}>
            <NextSkeleton className="rounded h-screen" />
          </Col>
          <Col span={7}>
            <NextSkeleton className="rounded h-[300px] ml-5" />
          </Col>
        </Row>
      ) : (
        <WorkflowView workflow={workflow} />
      )}
    </div>
  );
}
