import { Col, Row } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import "./_noConnection.scss";
import wifiIcon from "./images/wifi-off.svg";
import { useNavigate, useLocation } from "react-router-dom";

interface LocationType {
  state: {
    from?: string;
  };
}

/**
 *
 * @returns No connection screen
 */
export default function NoConnection() {
  const { t } = useTranslation();
  const { loading, connected } = useStatusContext();
  const navigate = useNavigate();
  const { state } = useLocation() as LocationType;

  useEffect(() => {
    if (!loading && connected) {
      navigate(state?.from || "/dashboard");
    }
  }, [connected, loading, navigate, state?.from]);

  return (
    <Row className="centered-container" justify="center" align="middle">
      <Col>
        <div className="container">
          <img src={wifiIcon} alt="No Connection" className="image" />
          <div className="restart-title">{t("translation:no_connection")}</div>
        </div>
        <p className="restart-message">
          {t("translation:no_connection_details")}
        </p>
        <p className="restart-instructions">
          {t("translation:no_connection_instructions")}{" "}
          <span className="email">{t("translation:itempath_support")}</span>
        </p>
      </Col>
    </Row>
  );
}
