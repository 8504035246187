import Descriptions from "/app/src/components/generic/components/descriptions";
import { dynamicFieldService } from "/app/src/services";
import { DynamicField as DynamicFieldType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
export default function DynamicFields({
  object,
  baseTable,
}: {
  object: any;
  baseTable: string;
}) {
  // Load Dynamic Field List for materials
  const { data: dynamicFields } = useQuery({
    queryKey: ["dynamicFields", baseTable],
    queryFn: () => {
      return dynamicFieldService.getAll(buildParams({ baseTable: baseTable }));
    },
    initialData: { dynamic_fields: [] },
    select: (data: { dynamic_fields: DynamicFieldType[] }) => {
      return data.dynamic_fields;
    },
  });

  return (
    <Descriptions
      items={dynamicFields.map((field) => ({
        label: field.name,
        value: field?.field && object[field.field],
      }))}
    />
  );
}
