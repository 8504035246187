import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { Row, Col } from "antd";
import { integrationService, settingService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";
import SideMenuBox from "/app/src/components/generic/components/sideMenuBox";
import Settings from "./settings";
import Logs from "./logs";
import {
  App,
  Integration as IntegrationType,
  Setting as SettingType,
} from "/app/src/models";
import { buildParams } from "/app/src/helpers";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function Integration({ app }: { app: App }) {
  const { integrationId } = useNumberId();

  const { data: integration } = useQuery({
    queryKey: ["integration", integrationId],
    queryFn: () => {
      return integrationService.getSingle(integrationId);
    },
    initialData: { integration: {} },
    select: (data: { integration: IntegrationType }) => {
      return data.integration;
    },
  });

  const { data: setting } = useQuery({
    queryKey: ["settingsByIntegration", integrationId, 11],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ integrationId: integrationId, number: 11 }))
        .then((response) => {
          return { setting: response.settings[0] };
        });
    },
    initialData: { setting: {} },
    select: (data: { setting: SettingType }) => {
      return data.setting;
    },
  });

  const links = [
    {
      path: `/apps/${app.id}/integrations/${integration.id}`,
      label: "settings",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/logs`,
      label: "logs",
    },
  ];

  return (
    <div className="integration" id="cycle_counts">
      <Helmet>
        <title>{integration.name}</title>
      </Helmet>

      <Row gutter={20}>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          lg={{ span: 6, order: 1 }}
        >
          <SideMenuBox links={links} />
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          lg={{ span: 18, order: 2 }}
        >
          {integration.id && (
            <Routes>
              <Route element={<Logs integration={integration} />} path="logs" />
              <Route
                path="/"
                element={
                  <Settings integration={integration} setting={setting} />
                }
              />
            </Routes>
          )}
        </Col>
      </Row>
    </div>
  );
}
