import axios from "axios";
import { URLSearchParams } from "url";
/**
 * Make an API request to the ItemPath server.
 * @param: {string} url - The url to make the request to.
 * @param: {string} method - The HTTP method to use.
 * @param: {any} data - The data to send in the request body.
 * @param: {any} headers - The headers to send in the request.
 * @param: {any} params - The query parameters to send in the request.
 * @param: {any} body - The body to send in the request.
 * @param: {any} responseType - The response type to send in the request.
 * @return {Promise<any>} Returns the promise containing data of API response.
 */
export function makeAPIRequest(options: {
  url: string;
  method: string;
  data?: any;
  headers?: any;
  params?: any;
  body?: any;
  responseType?: any;
}) {
  return axios
    .request({
      url: `/api${options.url}`,
      headers: { "Content-Type": "application/json", ...options.headers },
      params: options.params,
      method: options.method,
      data: JSON.stringify(options.body),
      ...(options.responseType && { responseType: options.responseType }),
      paramsSerializer: {
        indexes: null, // by default: false
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
}

/**
 * Gets all of the passed in model.
 * @param {!string} model string of the model to be fetched.
 * @param {!URLSearchParams} params URLSearchParams to be converted to query string.
 * @return {Promise<any>} Returns the promise containing data of API response.
 */
export function genericGetAll(model: string, params: URLSearchParams = null) {
  return makeAPIRequest({ url: `/${model}`, params, method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets a single instance of the passed in model and id.
 * @param {!string} model string of the model to be fetched.
 * @param {!number | string} id id of the model to be fetched.
 * @return {Promise<any>} Returns the promise containing data of API response.
 */
export function genericGetSingle(model: string, id: number | string) {
  return makeAPIRequest({
    url: `/${model}/${id}`,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Creates a single instance of the passed in model.
 * @param {!string} model string of the model to be created.
 * @param {!{[key: string]: string | number | boolean | Date | undefined}} body body of the model to be created.
 * @return {Promise<any>} Returns the promise containing data of API response.
 */
export function genericCreateSingle(
  model: string,
  body: {
    [key: string]: string | number | boolean | Date | undefined | string[];
  },
) {
  return makeAPIRequest({ url: `/${model}`, body, method: "post" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Updates a single instance of the passed in model and id.
 * @param {!string} model string of the model to be updated.
 * @param {!number | string} id id of the model to be updated.
 * @param {!{[key: string]: string | number | boolean | Date | undefined}} body body of the model to be updated.
 * @return {Promise<any>} Returns the promise containing data of API response.
 */
export function genericUpdateSingle(
  model: string,
  id: number | string,
  body: {
    [key: string]:
      | string
      | number
      | boolean
      | Date
      | undefined
      | string[]
      | { quantity: number; serialNumber: number }[];
  },
) {
  return makeAPIRequest({ url: `/${model}/${id}`, body, method: "put" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Deletes a single instance of the passed in model and id.
 * @param {!string} model string of the model to be deleted.
 * @param {!number | string} id id of the model to be deleted.
 * @return {Promise<any>} Returns the promise containing data of API response.
 */
export function genericDeleteSingle(model: string, id: number | string) {
  return makeAPIRequest({ url: `/${model}/${id}/delete`, method: "delete" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
