import React from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

export default function Filters({ setSearchString }) {
  return (
    <div className="integrationListFilters">
      <Search setSearchString={setSearchString} />
    </div>
  );
}

export function Search({ setSearchString }) {
  const { t } = useTranslation();
  return (
    <div className="search box">
      <Input.Search
        onSearch={(value) => setSearchString(value)}
        size="large"
        placeholder={t("search")}
      />
    </div>
  );
}
