export function convertStringToBoolean(string: string) {
  switch (string.toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
      return true;
    case "false":
    case "no":
    case "0":
    case null:
      return false;
    default:
      return Boolean(string);
  }
}

export function convertIntegerToBoolean(integer: number) {
  switch (integer) {
    case 1:
      return true;
    case 0:
    case null:
      return false;
    default:
      return Boolean(integer);
  }
}

export function convertDirectionType(number: string) {
  switch (parseInt(number)) {
    case 1:
      return "Put";
    case 2:
      return "Pick";
    case 3:
      return "Return";
    case 4:
      return "Transfer";
    case 5:
      return "Count";

    default:
      return "";
  }
}

/**
 * Converts a boolean to a string. If the passed in value is true,
 * it will return "True", otherwise it will return "False"
 */
export function convertBooleanToString(boolean: boolean) {
  return boolean ? "True" : "False";
}
/**
 * Converts array of strings to comma separated string. If
 * the passed in value is not an array, it will be returned
 * @param array Array of strings or string
 * @returns Comma separated string or the passed in value
 */
export function convertArrayToCSV(array: string[] | string) {
  if (Array.isArray(array)) {
    return array.join(",");
  }
  return array;
}

/**
 * Converts a comma separated string to an array of strings.
 * Trims the whitespace from each item in the array
 * @param csv the comma separated string
 * @returns Array of strings
 */
export function convertCSVtoArray(csv: string) {
  if (!csv) {
    return [];
  }
  return csv.split(",").map((item) => item.trim());
}

export function convertArrayToSemicolonSeparated(array: string[] | string) {
  if (Array.isArray(array)) {
    return array.join(";");
  }
  return array;
}

export function convertSemicolonSeparatedToArray(value: string) {
  if (!value) {
    return [];
  }
  return value.split(";").map((item) => item.trim());
}
