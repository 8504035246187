import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";

export default function Controls({
  activeNewWorkflow,
  toggleNewWorkflow,
}: {
  activeNewWorkflow: boolean;
  toggleNewWorkflow: () => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="controls">
      <Row>
        <Col span={8}>
          <h1>{t("translation:all_workflows")}</h1>
        </Col>
        <Col span={16}>
          <div className="controlButtons">
            {activeNewWorkflow ? (
              <NextButton
                size="sm"
                variant="bordered"
                color="primary"
                onClick={() => toggleNewWorkflow()}
                className="hover:border-blue-500 hover:text-blue-500 bg-white"
              >
                {t("translation:cancel")}
              </NextButton>
            ) : (
              <NextButton
                size="sm"
                color="primary"
                onClick={() => toggleNewWorkflow()}
              >
                {t("translation:new_workflow")}
              </NextButton>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
