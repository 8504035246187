import React from "react";
import { Row, Col, Select, DatePicker, Switch } from "antd";
import ClearLogs from "./clearLogs";
import { Search } from "/app/src/components/generic/components/search";
import { useTranslation } from "react-i18next";
import { User } from "/app/src/models";
import StatusFilter from "../generic/components/statusFilter";
import { reverseDateFormat } from "/app/src/helpers/time";
import { useTimezoneContext } from "../../contexts/hooks/useTimezoneContext";
import moment from "moment";
import "./_controls.scss";
import Tooltip from "/app/src/components/generic/components/toolTip";
const { Option } = Select;
const { RangePicker } = DatePicker;
type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];
export default function Controls({
  clearLogs,
  setSelectedStatus,
  setSelectedUser,
  setAfterTime,
  setBeforeTime,
  users,
  setSearchString,
  isTextLimitEnabled,
  toggleTextLimit,
}: {
  clearLogs: () => void;
  setSelectedStatus: (status: string) => void;
  setSelectedUser: (user: string) => void;
  setAfterTime: (time: string) => void;
  setBeforeTime: (time: string) => void;
  users: User[];
  setSearchString: (searchString: string) => void;
  isTextLimitEnabled: boolean;
  toggleTextLimit: (checked: boolean) => void;
}) {
  const { t } = useTranslation();
  const { timeZone } = useTimezoneContext();

  const handleUserChange = (value: string) => {
    setSelectedUser(value);
  };

  //handler for user changing the range of dates
  const onChangeHandler = (value: RangeValue) => {
    if (value) {
      if (value[0] !== null) {
        const formattedStartDateTime = reverseDateFormat(
          moment
            .tz(value[0].format("YYYY-MM-DD HH:mm:ss"), timeZone)
            .toISOString(),
          "YYYY-MM-DD HH:mm",
          timeZone,
        );
        setAfterTime(formattedStartDateTime);
      }
      if (value[1] !== null) {
        const formattedEndDateTime = reverseDateFormat(
          moment
            .tz(value[1].format("YYYY-MM-DD HH:mm:ss"), timeZone)
            .toISOString(),
          "YYYY-MM-DD HH:mm",
          timeZone,
        );
        setBeforeTime(formattedEndDateTime);
      }
    } else {
      setAfterTime("");
      setBeforeTime("");
    }
  };
  return (
    <div className="controls">
      <Row>
        <Col span={8}>
          <h1>
            {t("translation:api")} {t("translation:logs")}
          </h1>
        </Col>
        <Col span={16}>
          <div className="controlButtons">
            <ClearLogs clearLogs={clearLogs} />
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={5}>
          <div className="box">
            <StatusFilter setStatus={setSelectedStatus} />
          </div>
        </Col>
        <Col span={5}>
          <div className="box" key={123}>
            <Select
              size="large"
              placeholder={t("translation:select_user")}
              onChange={handleUserChange}
              style={{ width: "100%" }}
            >
              <Option key={"0"} value={"all"}>
                {t("translation:all")}
              </Option>
              <Option key={"1"} value={"none"}>
                {t("translation:none")}
              </Option>
              {users.map((c: User) => (
                <Option key={c.username} value={c.id}>
                  {c.username}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={5}>
          <div className="box">
            <RangePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              onChange={onChangeHandler}
              size="large"
              allowEmpty={[true, true]}
            />
          </div>
        </Col>
        <Col span={5}>
          <Search setSearchString={setSearchString} />
        </Col>
        <Col span={4}>
          <div className="box">
            <div className="box-content">
              <Switch checked={isTextLimitEnabled} onChange={toggleTextLimit} />
              <div className="text">{t("translation:character_limit")}</div>
              <Tooltip tip={t("translation:character_restrict")} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
