import React, { useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, InputNumber } from "formik-antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ToolTip from "/app/src/components/generic/components/toolTip";

import getInitialValues from "./formHelper";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  value: string | undefined;
}

/**
 * Form for creating and updating the request retries setting
 * @param integration Integration
 */
export default function Retries({ integration }: { integration: Integration }) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const retriesSettingQuery = useQuery({
    queryKey: ["setting", "retries", integration.id],
    queryFn: () =>
      settingService.getAll(
        buildParams({ integrationId: integration.id, type: "retries" }),
      ),
  });

  const { mutateAsync: retriesMutation } = useMutation({
    mutationFn: (values: FormValues) => {
      if (retriesSettingQuery.data?.settings.length < 1) {
        return settingService
          .createSingle({
            name: "retries",
            value: values.value,
            type: "retries",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      } else {
        return settingService
          .updateSingle(retriesSettingQuery.data?.settings[0]?.id, {
            value: values.value,
          })
          .then(handlePromiseError);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["setting", "retries", integration.id], {
        settings: [data.setting],
      });
    },
  });

  const retriesHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await retriesMutation(values).finally(() => {
        actions.resetForm();
      });
    },
    [retriesMutation],
  );

  /**
   * Form for the request retries setting
   */
  const retriesForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item
                name="value"
                label={
                  <>
                    {t("translation:retries")}
                    <ToolTip tip={t("translation:retries_tip")} />
                  </>
                }
                hasFeedback={false}
              >
                <InputNumber
                  name="value"
                  min={0}
                  max={5}
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="1"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  return (
    <Formik
      component={retriesForm}
      initialValues={{
        value: getInitialValues(retriesSettingQuery.data?.settings[0]?.value),
      }}
      enableReinitialize
      onSubmit={retriesHandler}
    />
  );
}
