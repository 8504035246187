import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { stationService, warehouseService } from "/app/src/services";
import Search from "../search";
import { useDebounce } from "/app/src/hooks";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";

import {
  Station as StationType,
  Warehouse as WarehouseType,
} from "/app/src/models";
import { Row, Col, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
const { Option } = Select;

/**
 * Shows a table with all stations
 */
export default function StationsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [typeFilter, setTypeFilter] = useState("all");
  const [warehouseFilter, setWarehouseFilter] = useState("all");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");
  const debouncedSearchTerm = useDebounce(searchString, 1000);

  //This array is used to map the station type to its description.
  //These descriptions were provided by Bill: https://linear.app/itempath/issue/DEV-423#comment-578e62e1
  const stationTypes = [
    { value: 1, description: t("translation:pc") },
    { value: 5, description: t("translation:web_client") },
    { value: 6, description: t("translation:server") },
    { value: 7, description: t("translation:mobile_client") },
    { value: 8, description: t("translation:terminal_client") },
  ];

  const columnHelper = createColumnHelper<StationType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => info.getValue(),
        header: () => t("translation:description"),
      }),
      columnHelper.accessor("warehouseName", {
        id: "warehouseName",
        cell: (info) => info.getValue(),
        header: () => t("translation:warehouse"),
      }),
      columnHelper.accessor("typeDescription", {
        id: "typeDescription",
        cell: (info) => info.getValue(),
        header: () => t("translation:type"),
      }),
    ],
    [columnHelper, t],
  );

  const goToStation = (id: number) => {
    navigate(`/explorer/stations/${id}`);
  };

  const { data: warehouses } = useQuery({
    queryKey: ["warehouses"],
    queryFn: () => {
      return warehouseService.getAll();
    },
    initialData: { warehouses: [] },
    select: (data: { warehouses: WarehouseType[] }) => {
      return data.warehouses;
    },
  });

  const { data: stations, isFetching } = useQuery({
    queryKey: [
      "stations",
      page,
      pageSize,
      debouncedSearchTerm,
      warehouseFilter,
      typeFilter,
      sort,
    ],
    queryFn: () => {
      return stationService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          search: debouncedSearchTerm,
          type: typeFilter,
          warehouseId: warehouseFilter,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { stations: [] },
    select: (data: { stations: StationType[] }) => {
      return data.stations;
    },
  });

  const { data: stationsCount } = useQuery({
    queryKey: ["stationsCount", searchString],
    queryFn: () => {
      return stationService.getCount(buildParams({ search: searchString }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:stations")} - ItemPath</title>
      </Helmet>
      <Row className="box">
        <Col span={12}>
          <Search setSearchString={setSearchString} />
        </Col>
        <Col span={6}>
          <Select
            onChange={(value) => {
              setWarehouseFilter(value);
            }}
            size="large"
            style={{ width: "100%" }}
            placeholder={t("translation:filter_by_warehouse")}
          >
            <Option value="all">{t("translation:all")}</Option>
            {warehouses.map((warehouse) => (
              <Option value={warehouse.id} key={warehouse.id}>
                {warehouse.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Select
            onChange={(value) => {
              setTypeFilter(value);
            }}
            size="large"
            style={{ width: "100%" }}
            placeholder={t("translation:filter_by_station_type")}
          >
            <Option value="all">{t("translation:all")}</Option>

            {stationTypes.map((type) => (
              <Option value={type.value} key={type.value}>
                {type.description}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <TableUpgrade
        loading={isFetching}
        rows={stations}
        tableColumns={columns}
        length={stationsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        enableRowSelection
        rowClicked={goToStation}
        emptyText={t("translation:no_stations_found")}
      />
    </div>
  );
}
