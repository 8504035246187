import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Integration, Log, Task } from "/app/src/models";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import Controls from "./_controls";
import LogsTable from "/app/src/components/generic/components/tasks/logsTable";
import { createColumnHelper } from "@tanstack/react-table";
import TasksTable from "/app/src/components/generic/components/tasks/tasksTable";
import { Col, Row, message } from "antd";
import { taskService } from "/app/src/services";
import StatusIcon from "/app/src/components/generic/components/statusIcon";
import { getJSON, getKeyValue, parseOutData } from "/app/src/helpers";
import NextButton from "/app/src/components/NextUi/Button";

/**
 * Component for displaying the logs of a data push integration
 * @param integration the integration to display the logs for
 */
export default function DataPullLogs({
  integration,
}: {
  integration: Integration;
}) {
  const [searchString, setSearchString] = useState("");
  const [status, setStatus] = useState<string>("");
  const { t } = useTranslation();
  const [taskId, setTaskId] = useState<number | undefined>(undefined);
  const [logParams, setLogParams] = useState(undefined);
  const [taskParams, setTaskParams] = useState(undefined);

  useEffect(() => {
    setTaskParams({
      integrationId: integration.id,
      search: searchString,
      status,
    });
  }, [integration.id, searchString, status]);

  useEffect(() => {
    setLogParams({
      integrationId: integration.id,
      taskId,
    });
  }, [integration.id, taskId]);

  const columnHelper = createColumnHelper<Log>();
  const taskColumnHelper = createColumnHelper<Task>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => <DateTime date={info.getValue()} />,
        header: () => t("translation:creationDate"),
        meta: {
          clickable: false,
        },
      }),
      columnHelper.accessor("description", {
        id: "description",
        header: () => t("translation:description"),
        meta: {
          clickable: false,
        },
      }),
      columnHelper.accessor("status", {
        id: "status",
        cell: (info) => {
          const value = info.getValue();
          return <StatusIcon status={value} />;
        },
        header: () => t("translation:status"),
        meta: {
          clickable: false,
        },
      }),
    ],
    [columnHelper, t],
  );

  const taskColumns = useMemo(
    () => [
      taskColumnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => <DateTime date={info.getValue()} />,
        header: t("translation:time"),
      }),
      taskColumnHelper.accessor("data", {
        id: "data",
        header: () => t("translation:data"),
        cell: (info) => (
          <p>{getJSON(parseOutData(info.getValue(), "record"))}</p>
        ),
      }),
      taskColumnHelper.accessor("data", {
        id: "test",
        header: () => t("translation:test"),
        cell: (info) =>
          getKeyValue(info.getValue(), "isTest") ? (
            <StatusIcon status={1} />
          ) : (
            <> </>
          ),
      }),
      taskColumnHelper.accessor("status", {
        id: "status",
        cell: (info) => {
          const value = info.getValue();
          return <StatusIcon status={value} />;
        },
        header: t("translation:status"),
      }),
    ],
    [taskColumnHelper, t],
  );

  const handleClick = useCallback(async () => {
    await taskService
      .updateSingle(taskId, { retry: "true" })
      .then((res: { retried: boolean }) => {
        if (res?.retried) {
          message.success(t("translation:task_retried"));
        } else {
          message.error(t("translation:task_retry_failed"));
        }
      });
  }, [t, taskId]);

  return (
    <Row gutter={16}>
      <Col span={10}>
        <Controls setSearchString={setSearchString} setStatus={setStatus} />
        <TasksTable
          integrationId={integration.id}
          emptyText={t("translation:no_tasks_found")}
          setTaskId={setTaskId}
          params={taskParams}
          refresh
          columns={taskColumns}
        />
      </Col>
      <Col span={14}>
        {taskId !== undefined && (
          <>
            <NextButton
              color="primary"
              fullWidth
              onClick={handleClick}
              className="mb-2"
            >
              {t("translation:retry")}
            </NextButton>

            <LogsTable
              notFoundText={t("translation:no_logs_found")}
              columns={columns}
              params={logParams}
              refresh
            />
          </>
        )}
      </Col>
    </Row>
  );
}
