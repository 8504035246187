/**
 * Determines if the status code is a success code (2xx)
 * @param status the status code to check
 * @returns true if the status code is a success code
 */
export function isHTTPSuccess(status: number): boolean {
  return status >= 200 && status < 300;
}

export function handlePromiseError(response: any): Promise<any> {
  if ("errors" in response) {
    return Promise.reject(response.errors);
  }
  return Promise.resolve(response);
}
