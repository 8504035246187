import React, { useState } from "react";
import { Row, Col, Select } from "antd";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";

export default function InnerControls({
  count,
  directionType,
  setDirectionType,
  setSearchString,
  processOrders,
  countTypeEnabled,
}: {
  count: number;
  directionType: number;
  setDirectionType: (directionType: number) => void;
  setSearchString: (searchString: string) => void;
  processOrders: () => void;
  countTypeEnabled: boolean;
}) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  return (
    <Row gutter={[10, 10]} style={{ paddingBottom: "10px" }}>
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 18, order: 1 }}
        lg={{ span: 20, order: 1 }}
        xl={{ span: 21, order: 1 }}
      >
        <Row gutter={20}>
          <Col>
            <NextButton
              size="md"
              color={directionType === 2 ? "primary" : "default"}
              onClick={() => {
                setDirectionType(2);
              }}
            >
              {t("translation:pick")}
            </NextButton>
            <NextButton
              size="md"
              color={directionType === 1 ? "primary" : "default"}
              onClick={() => {
                setDirectionType(1);
              }}
            >
              {t("translation:put")}
            </NextButton>
            {countTypeEnabled && (
              <NextButton
                size="md"
                color={directionType === 5 ? "primary" : "default"}
                onClick={() => {
                  setDirectionType(5);
                }}
              >
                {t("translation:count")}
              </NextButton>
            )}
          </Col>
          <Col
            xs={{ span: 15, order: 1 }}
            sm={{ span: 16, order: 1 }}
            md={{ span: 14, order: 1 }}
            lg={{ span: 10, order: 1 }}
          >
            <Select
              autoClearSearchValue={false}
              size="large"
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => {
                setSearchString(value);
              }}
              open={false}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </Col>

      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 6, order: 2 }}
        lg={{ span: 4, order: 2 }}
        xl={{ span: 3 }}
      >
        <NextButton
          fullWidth
          isDisabled={!count || disabled}
          color="primary"
          size="md"
          onClick={() => {
            setDisabled(true);
            processOrders();
          }}
          className="float-right"
        >
          {count <= 1
            ? `${t("translation:process")} ${t("translation:order")}`
            : `${t("translation:process")} ${t("translation:orders")}`}
        </NextButton>
      </Col>
    </Row>
  );
}
