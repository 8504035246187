import React from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

export default function Search({
  setSearchString,
}: {
  setSearchString: (searchString: string) => void;
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Input.Search
        allowClear
        onSearch={(value) => setSearchString(value)}
        size="large"
        placeholder={t("translation:search")}
      />
    </div>
  );
}
