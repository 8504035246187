import React from "react";
import "moment-timezone";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

/**
 * Function to display the status icon for a status. Green check mark for 1, red x for 0
 * @param param0 the value of the status
 * @returns the status icon
 */
export default function Status({ value }: { value: string | number }) {
  if (String(value) === "1") {
    return <CheckOutlined style={{ color: "green" }} />;
  } else {
    return <CloseOutlined style={{ color: "red" }} />;
  }
}
